import UserApi from './components/User';
import Subscriptions from './components/Subscriptions';
import PersonalPlan from './components/PersonalPlan';

function ApiInstance() {
    const user = new UserApi();
    const subscriptions = new Subscriptions();
    const personalPlan = new PersonalPlan();

    return {
        user,
        subscriptions,
        personalPlan,
    };
}

export default ApiInstance();
