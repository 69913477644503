import styled, { keyframes } from 'styled-components';

export const loaderAnimation = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
`;

export const LoaderContainer = styled.div`
    width: 100%;
    max-width: 432px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    > div:last-of-type {
        animation: ${loaderAnimation} 2s linear infinite;
        width: fit-content;
        height: 32px;
    }
`;
