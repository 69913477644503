import * as action from 'store/modals/actions';

import * as actionTypes from './actionTypes';

import { ModalName } from 'components/Modals/types';

import { InferValueTypes } from 'types/commonTypes';

export type ActionType = ReturnType<InferValueTypes<typeof action>>;

export type ModalsState = {
    modalId?: ModalName;
    modalProps?: any;
};

const initialState: ModalsState = {};

const modalsReducer = (state = initialState, action: ActionType): ModalsState => {
    switch (action.type) {
        case actionTypes.OPEN_MODAL:
            return { ...state, ...action.payload };

        case actionTypes.CLOSE_MODAL:
            return { ...initialState };

        default:
            return state;
    }
};

export default modalsReducer;
