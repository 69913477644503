import { Button } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import { useCancelSubscriptionFlow, useNotify } from 'hooks';

import { CancelSubscriptionErrorModalPropsType } from './types';

import { FeedbackModalTemplate } from '../../Templates';

const dataLocators = {
    title: 'unsubscribeErrorTitle',
    body: 'unsubscribeErrorBlock',
};

const CancelSubscriptionErrorModal = ({
    img,
    title,
    subtitle,
    wasSecondCanceled,
}: CancelSubscriptionErrorModalPropsType) => {
    const { onCancelSubscription } = useCancelSubscriptionFlow();
    const { t } = useTranslation();
    const { notifyError } = useNotify();

    return (
        <FeedbackModalTemplate
            dataLocators={dataLocators}
            image={img}
            title={title}
            subtitle={subtitle}
            contactUs={{ text: 'basics.contactSupport', subject: t('subscription.cancel.error.title') }}
            withCloseButton
            onClose={() => notifyError('subscription.cancel.error.subtitle.single')}
        >
            <Button
                dataLocator="unsubscribeErrorTryAgainButton"
                backgroundColor="primary-default"
                text={t('basics.tryAgain')}
                onClick={() => onCancelSubscription(wasSecondCanceled)}
                mb={24}
            />
        </FeedbackModalTemplate>
    );
};

export default CancelSubscriptionErrorModal;
