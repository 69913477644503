import FitnessLvl3 from 'assets/img/fitnessLvl3.svg?react';
import FitnessLvl2 from 'assets/img/fitnessLvl2.svg?react';
import FitnessLvl1 from 'assets/img/fitnessLvl1.svg?react';

import { FitnessLevel } from 'constants/user';

const FITNESS_LEVEL_CARDS = [
    {
        title: 'basics.user.fitnessLevel.beginner',
        description: 'basics.user.fitnessLevel.description.beginner',
        icon: FitnessLvl1,
        id: FitnessLevel.Beginner,
    },
    {
        title: 'basics.user.fitnessLevel.intermediate',
        description: 'basics.user.fitnessLevel.description.intermediate',
        icon: FitnessLvl2,
        id: FitnessLevel.Intermediate,
    },
    {
        title: 'basics.user.fitnessLevel.advanced',
        description: 'basics.user.fitnessLevel.description.advanced',
        icon: FitnessLvl3,
        id: FitnessLevel.Advanced,
    },
];

export { FITNESS_LEVEL_CARDS };
