import { useNavigate } from 'react-router-dom';

import routes from 'router/routes';

import { useSidebar } from 'hooks';

import Logo from 'components/Logo';
import Burger from 'components/Burger';

import * as S from './styled';

import defaultUserPic from 'assets/images/default-avatar.svg';

const Header = ({ noControls }: { noControls?: boolean }) => {
    const navigate = useNavigate();

    const { isOpen, toggle, close } = useSidebar();

    const handleRedirect = () => {
        navigate(routes.PRIVATE.ACCOUNT_DEFAULT.path);
        close();
    };

    return (
        <S.HeaderContainer>
            <S.TitleContainer>
                {noControls && <Burger onClick={toggle} isActive={isOpen} />}
                <Logo />
            </S.TitleContainer>
            {noControls && <S.AvatarImage onClick={handleRedirect} src={defaultUserPic} />}
        </S.HeaderContainer>
    );
};

export default Header;
