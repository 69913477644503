import { useCallback, useEffect, useRef } from 'react';

import { selectMainSessionId } from 'store/session/selectors';

import { sendAnalyticSessionEvent } from 'services/analytics/trackers/session';

import { useSelector } from 'hooks/store';

import { getDurationInSeconds } from './helpers';

import { SessionEvent } from './types';

const useMainSessionTracker = () => {
    const mainSessionStartTime = useRef(0);
    const mainSessionId = useSelector(selectMainSessionId);

    const startMainSessionHandler = useCallback(() => {
        if (!mainSessionId) return;

        mainSessionStartTime.current = Date.now();

        sendAnalyticSessionEvent(SessionEvent.StartMainSession, { session_id: mainSessionId });
    }, [mainSessionId]);

    const endMainSessionHandler = useCallback(() => {
        if (!mainSessionId) return;

        sendAnalyticSessionEvent(SessionEvent.EndMainSession, {
            session_id: mainSessionId,
            duration: getDurationInSeconds(mainSessionStartTime.current),
        });

        mainSessionStartTime.current = 0;
    }, [mainSessionStartTime, mainSessionId]);

    useEffect(() => {
        startMainSessionHandler();
    }, [startMainSessionHandler]);

    return {
        startMainSessionHandler,
        endMainSessionHandler,
    };
};

export default useMainSessionTracker;
