import * as actionTypes from 'store/user/actionTypes';

import { UserStore } from 'types/store/userStore';
import { SagaActionsProps } from 'types/commonInterfaces';
import { ChangePasswordRequest, BaseSendEmailRequest, UpdateUserRequest, UserState } from './types';

export const getCurrentUserRequest = (payload?: SagaActionsProps) => {
    return {
        type: actionTypes.GET_CURRENT_USER_REQUEST,
        payload,
    } as const;
};

export const getCurrentUserSuccess = (payload: UserStore) => {
    return {
        type: actionTypes.GET_CURRENT_USER_SUCCESS,
        payload,
    } as const;
};

export const getCurrentUserError = (payload: Error) => {
    return {
        type: actionTypes.GET_CURRENT_USER_ERROR,
        payload,
    } as const;
};

export const updateUserEmail = (payload: BaseSendEmailRequest) => {
    return {
        type: actionTypes.UPDATE_USER_EMAIL,
        payload,
    } as const;
};

export const updateUserRequest = (payload: UpdateUserRequest) => {
    return {
        type: actionTypes.UPDATE_USER_REQUEST,
        payload,
    } as const;
};

export const updateUserSuccess = (payload: UserStore) => {
    return {
        type: actionTypes.UPDATE_USER_SUCCESS,
        payload,
    } as const;
};

export const updateUserError = (payload: UserState['user_error']) => {
    return {
        type: actionTypes.UPDATE_USER_ERROR,
        payload,
    } as const;
};

export const forgotPasswordRequest = (payload: BaseSendEmailRequest) => {
    return {
        type: actionTypes.FORGOT_PASSWORD_REQUEST,
        payload,
    } as const;
};

export const forgotPasswordSuccess = (payload: UserStore) => {
    return {
        type: actionTypes.FORGOT_PASSWORD_SUCCESS,
        payload,
    } as const;
};

export const forgotPasswordError = (payload: Error) => {
    return {
        type: actionTypes.FORGOT_PASSWORD_ERROR,
        payload,
    } as const;
};

export const changePasswordRequest = (payload: ChangePasswordRequest) => {
    return {
        type: actionTypes.CHANGE_PASSWORD_REQUEST,
        payload,
    } as const;
};

export const changePasswordSuccess = () => {
    return {
        type: actionTypes.CHANGE_PASSWORD_SUCCESS,
    } as const;
};

export const changePasswordError = (payload: Error) => {
    return {
        type: actionTypes.CHANGE_PASSWORD_ERROR,
        payload,
    } as const;
};

export const resetUserData = () => {
    return {
        type: actionTypes.RESET_USER_DATA,
    } as const;
};

export const getUserFeatures = () => {
    return {
        type: actionTypes.GET_USER_FEATURES,
    } as const;
};

export const setUserFeatures = (payload: string[]) => {
    return {
        type: actionTypes.SET_USER_FEATURES,
        payload,
    } as const;
};
