import { notify, notifyError, notifySuccess } from 'store/notifications/actions';

import { useDispatch } from '../store';

const useNotify = () => {
    const dispatch = useDispatch();

    return {
        notify: (message: string) => dispatch(notify(message)),
        notifyError: (message: string) => dispatch(notifyError(message)),
        notifySuccess: (message: string) => dispatch(notifySuccess(message)),
    };
};

export default useNotify;
