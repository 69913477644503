import { Box, Colors } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const Container = styled(Box)<{ backgroundColor?: Colors }>`
    width: 100%;
    > * {
        background-color: ${({ backgroundColor }) =>
            backgroundColor ? color(backgroundColor) : 'transparent'} !important;
    }
`;
