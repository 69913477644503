import { PropsWithChildren } from 'react';

import * as S from './styled';

import { RoundButtonType } from './types';

const RoundButton = ({ onClick, size = 44, children, disabled = false }: PropsWithChildren<RoundButtonType>) => {
    return (
        <S.Button type="button" onClick={onClick} size={size} disabled={disabled}>
            {children}
        </S.Button>
    );
};

export default RoundButton;
