import { css, styled } from 'styled-components';

import { borderRadius, color } from 'theme/selectors';

export const Pill = styled.div<{ isActive?: boolean }>`
    border-radius: ${borderRadius('medium')};
    background: ${color('surface-default')};
    padding: 16px;
    cursor: pointer;
    border: 2px solid
        ${({ isActive }) =>
            css`
                ${color(isActive ? 'primary-default' : 'surface-default')}
            `};
`;

export const PillWrapper = styled.div`
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;
`;
