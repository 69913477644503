export enum CancellationFlow {
    withCancellingOffer = 'PROCEED_WITH_CANCELLING_OFFER',
    withSupport = 'PROCEED_WITH_SUPPORT',
}

export interface IUserFeedback {
    reason: IReason;
    feedback: string;
}

export interface IReason {
    title: string;
    shortKey: string;
    flow: string;
}
