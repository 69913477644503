import { SIGNATURE_PARAM_NAME, USER_ID_PARAM_NAME } from 'constants/authentication';
import { AUTH_TOKEN_NAME } from 'constants/api';

const EXCLUDED_AUTH_QUERY_PARAMS = [USER_ID_PARAM_NAME, SIGNATURE_PARAM_NAME, AUTH_TOKEN_NAME];

export const getAllowedAuthQueryParams = (search: string) => {
    const params = new URLSearchParams(search);

    EXCLUDED_AUTH_QUERY_PARAMS.forEach((param) => params.delete(param));

    return params.size > 0 ? `?${params.toString()}` : '';
};
