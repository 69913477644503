import { RootReducer } from 'types/store';

export const selectCompletedPlanStatistics = (state: RootReducer) => state.personalPlan.statistics;
export const selectPlanDays = (state: RootReducer) => state.personalPlan.planDays;
export const selectIsCurrentPlanComplete = (state: RootReducer) => state.personalPlan.isCurrentPlanComplete;
export const selectCurrentDay = (state: RootReducer) => state.personalPlan.currentDay;
export const selectWorkout = (state: RootReducer) => state.personalPlan.workout;
export const selectCurrentPlanId = (state: RootReducer) => state.personalPlan.currentPlanId;
export const selectCurrentPlanLevel = (state: RootReducer) => state.personalPlan.currentPlanLevel;
export const selectTotalPlanDays = (state: RootReducer) => state.personalPlan.totalPlanDays;
export const selectIsCompletePlanModalVisible = (state: RootReducer) => state.personalPlan.isCompletePlanModalVisible;
export const selectYogaType = (state: RootReducer) => state.personalPlan.yogaType;
export const selectPersonalPlan = (state: RootReducer) => state.personalPlan;
