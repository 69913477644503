import { useState, useEffect } from 'react';

import { useModals } from 'hooks';

import { ModalName } from 'components/Modals/types';

const useNetworkStatus = () => {
    const [isOnline, toggleNetworkStatus] = useState(window.navigator.onLine);

    const { openModal, closeModal } = useModals();

    useEffect(() => {
        isOnline ? closeModal() : openModal(ModalName.NoConnectionModal);
    }, [isOnline, openModal, closeModal]);

    useEffect(() => {
        const onNetworkStatusChange = () => toggleNetworkStatus((prev) => !prev);

        window.addEventListener('online', onNetworkStatusChange);
        window.addEventListener('offline', onNetworkStatusChange);

        return () => {
            window.removeEventListener('online', onNetworkStatusChange);
            window.removeEventListener('offline', onNetworkStatusChange);
        };
    }, []);

    return isOnline;
};

export { useNetworkStatus };
