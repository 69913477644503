import { Box, Text as ComponentsText, Option } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';
import { mediaQueriesNew } from 'theme/mediaQueries';

export const ModalWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    line-height: initial;
`;

export const Content = styled(Box)`
    width: 100%;
    padding: 16px;
    ${mediaQueriesNew.tablet} {
        padding: 32px;
    }
    ${mediaQueriesNew.desktop} {
        max-width: 424px;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    gap: 12px;
`;

export const Title = styled(ComponentsText)`
    color: ${color('surface-main')};
    ${mediaQueriesNew.desktop} {
        font-size: 36px;
        line-height: 44px;
    }
`;

export const Subtitle = styled(ComponentsText)`
    color: ${color('surface-main')};
`;

export const Checkbox = styled(Option)`
    border: 1px solid #ffffff;
    margin-bottom: 16px;
    p {
        color: ${color('surface-main')};
    }
    input {
        &:checked + div div:nth-child(2) {
            border: 2px solid ${color('primary-default')} !important;
            background-color: ${color('primary-default')} !important;
            svg,
            svg > * {
                fill: ${color('text-main')} !important;
            }
        }
        + div {
            background-color: transparent;
            padding-left: 18px;
            display: flex;
            gap: 12px;
            align-items: center;
            justify-content: center;
            min-height: 64px;
            div:first-child {
                width: auto;
                order: 2;
            }

            div:nth-child(2) {
                position: static;
                order: 1;
                transform: initial;
            }
        }
    }
`;
