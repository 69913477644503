import { ShouldForwardProp } from 'styled-components';

import isPropValid from '@emotion/is-prop-valid';

// This solution is from https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
export const shouldForwardProp: ShouldForwardProp<'web'> = (propName, target) => {
    if (typeof target === 'string') {
        return isPropValid(propName);
    }

    return true;
};
