import { createSelector } from 'reselect';

import { iUiStore } from 'types/ui';
import { RootReducer } from 'types/store';

const selectUi = (state: RootReducer) => state.ui;

const selectIsOpenSidebar = createSelector(selectUi, (ui: iUiStore) => ui.isOpenSidebar);

export { selectIsOpenSidebar };
