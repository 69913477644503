import { Box } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';

export const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 67px;
    height: 28px;
    padding: 4px 16px;
    border-radius: 6px;
    background-color: ${color('decorative-b')};
`;
