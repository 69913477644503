export enum GENDER {
    Male = 'male',
    Female = 'female',
}
// fitness level = yoga level = pilates level
export enum FitnessLevel {
    Beginner = 'beginner',
    Intermediate = 'intermediate',
    Advanced = 'advanced',
}

export enum ProblemZone {
    Shoulders = 'shoulders',
    Buttocks = 'buttocks',
    Back = 'back',
    Arms = 'arms',
    Legs = 'legs',
    Belly = 'belly',
    Core = 'core',
    Chest = 'chest',
    Face = 'face',
    // Elbows = 'elbows',
    // Wrists = 'wrists',
    // Feet = 'feet',
    Neck = 'neck',
}

export const ALL_PROBLEM_ZONES = 'whole_body';
