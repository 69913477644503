import { Box } from 'wikr-core-components';
import styled, { css } from 'styled-components';

import { SIDEBAR_WIDTH } from './constants';

import { color, viewPortWithoutHeader } from 'theme/selectors';
import { mediaQueries } from 'theme/mediaQueries';

export const SideBarMenu = styled.div`
    position: absolute;
    top: 0;
    left: -100%;
    width: ${SIDEBAR_WIDTH}px;
    height: 100%;
    padding: 8px;
    box-shadow: 0 4px 12px rgb(0 0 0 / 12%);
    background: ${color('surface-default')};
    transition: all 0.3s;
`;

export const SideBarOverlay = styled.div`
    width: 100vw;
    height: 100vh;
    background: ${color('surface-main')};
    opacity: 0;
    transition: opacity 0.4s ease;
`;

export const SideBarWrapper = styled.div<{ isOpen: boolean }>`
    position: fixed;
    top: 56px;
    left: 0;
    height: ${viewPortWithoutHeader()};
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    z-index: 10000;

    ${({ isOpen }) =>
        isOpen &&
        css`
            ${mediaQueries.mobile} {
                opacity: 1;
                visibility: visible;

                ${SideBarOverlay} {
                    opacity: 0.8;
                    visibility: visible;
                }

                ${SideBarMenu} {
                    left: 0;
                }
            }
        `}
`;

export const Hr = styled.div`
    width: 100%;
    min-height: 1px;
    background-color: ${color('border-default')};
`;

const commonSecondaryButtonStyles = css`
    display: table;
    cursor: pointer;
    &:hover {
        * {
            color: ${color('text-secondary')};
        }
    }
`;

export const LinkSecondaryButton = styled.a`
    ${commonSecondaryButtonStyles}
`;

export const NavSecondaryButton = styled.button`
    text-align: left;
    ${commonSecondaryButtonStyles}
`;

export const NavButton = styled.button`
    display: block;
    width: 100%;
    text-align: left;
    align-items: center;
    background: transparent;
    cursor: pointer;
    position: relative;
    border-radius: 8px;

    p {
        display: flex;
        align-items: center;
    }
`;

export const PrimaryLinksBox = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const SidebarLinkWrapper = styled(Box)`
    display: flex;
    justify-content: space-between;
`;
