import { Button } from 'wikr-core-components';

import { useModals } from 'hooks';

import { SuccessModalPropsType } from './types';

import { FeedbackModalTemplateOld } from '../../Templates';

const SuccessModal = ({
    image,
    title,
    subtitle,
    buttonText,
    withCloseButton,
    withRoundedCorners,
    dataLocators = {},
}: SuccessModalPropsType) => {
    const { closeModal } = useModals();

    return (
        <FeedbackModalTemplateOld
            dataLocators={dataLocators}
            image={image}
            title={title}
            subtitle={subtitle}
            withCloseButton={withCloseButton}
            withRoundedCorners={withRoundedCorners}
        >
            <Button
                dataLocator="successContinueButton"
                backgroundColor="primary-default"
                text={buttonText}
                onClick={closeModal}
            />
        </FeedbackModalTemplateOld>
    );
};

export default SuccessModal;
