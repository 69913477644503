import styled from 'styled-components';

import { color } from 'theme/selectors';
import { mediaQueries } from 'theme/mediaQueries';

export const DiscountModalHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid ${color('border-default')};

    ${mediaQueries.laptop} {
        padding-left: 32px;
        padding-right: 32px;
        margin-bottom: 8px;
    }

    ${mediaQueries.desktop} {
        padding-left: 40px;
        padding-right: 40px;
    }
`;
