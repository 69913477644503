import { css } from 'styled-components';

const common = css`
    .container-sm {
        width: 100%;
        max-width: 432px;
        padding-left: 16px;
        padding-right: 16px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }
`;

export default common;
