import timeSrc from './img/time.png';
import caloriesSrc from './img/calories.png';
import calendarSrc from './img/calendar.png';

import { IStatisticPersonalPlanResponse } from 'types/personalPlan';

export const getContent = (progressData: IStatisticPersonalPlanResponse['data']) => {
    const { days, duration, kcal } = progressData;

    return {
        progressData: [
            {
                icon: calendarSrc,
                alt: '',
                title: 'personalPlan.completePlanModal.resultDays',
                count: days || '-',
                dataLocator: 'acriveDaysPlan',
            },
            {
                icon: timeSrc,
                alt: '',
                title: 'personalPlan.completePlanModal.resultMinutes',
                count: duration || '-',
                dataLocator: 'spentMinutesPlan',
            },
            {
                icon: caloriesSrc,
                alt: '',
                title: 'personalPlan.completePlanModal.resultCalories',
                count: kcal || '-',
                dataLocator: 'calBurnedPlan',
            },
        ],
    };
};
