import { LogoImage } from 'components/Logo';

import * as S from './styled';

import logo from 'assets/images/logo.svg';

export const PageLoader = () => (
    <S.Container backgroundColor="surface-main">
        <LogoImage alt="logo" src={logo} />
    </S.Container>
);
