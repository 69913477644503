import { TFunction } from 'i18next';

import * as Yup from 'yup';

const MAX_LENGTH = 200;

const getValidationSchema = (t: TFunction) =>
    Yup.object().shape({
        feedback: Yup.string().max(
            200,
            t('validation.length.max', {
                count: MAX_LENGTH,
            })
        ),
    });

export default getValidationSchema;
