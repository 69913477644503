import { ActionEventAction, ElementEventAction, Main } from 'wikr-core-analytics';

import { ComplexityLevels } from 'containers/Workouts/WorkoutCompleted/types';

import { trackClick, trackEventFixed } from './mainTrackers';

export const sendAnalyticExerciseCompleted = ({ exerciseIndex }: { exerciseIndex: string }) => {
    // @ts-ignore
    trackEventFixed('exercise_complete', exerciseIndex);
};

export const sendAnalyticSelectComplexityLevelClick = (complexityLevel: keyof typeof ComplexityLevels) => {
    trackClick({
        actionEvent: ActionEventAction.select,
        eventLabel: complexityLevel,
        elementEvent: ElementEventAction.button,
    });
};

export const sendAnalyticExerciseFinishClick = ({ exerciseIndex }: { exerciseIndex: string }) => {
    trackClick({
        // @ts-ignore
        actionEvent: 'finish',
        elementEvent: ElementEventAction.button,
        eventLabel: exerciseIndex,
    });
};

export const sendAnalyticPlayerCloseClick = (eventLabel: { [keys: string]: any }) => {
    trackClick({
        screenIdParam: 'player_close',
        eventLabel,
    });
};

export const sendAnalyticVideoControlActionClick = (eventLabel: { [keys: string]: any }) => {
    trackClick({
        screenIdParam: 'video_control_action',
        eventLabel,
    });
};

export const sendAnalyticMarkAsCompleteClick = (eventLabel: { [keys: string]: any }) => {
    trackClick({
        screenIdParam: 'mark_as_complete',
        eventLabel,
    });
};

export const sendPlayerTrainingComplete = (eventLabel: { [keys: string]: any }) => {
    Main.trackNew({
        eventData: 'player__training__complete',
        ...(eventLabel && { data: { event_label: eventLabel } }),
    });
};

export const sendPlayerFullScreenClick = (eventLabel: { [keys: string]: any }) => {
    trackClick({
        screenIdParam: 'player_full_screen',
        eventLabel,
    });
};
