import { Image, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import OutlinedButton from 'components/OutlinedButton';
import Modal from 'components/Modal';

import globe_with_meridians from 'assets/emoji/globe_with_meridians.webp';

import { ErrorSubtitle } from './styles';

const NoConnectionModal = () => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const onClick = () => {
        setIsLoading(true);

        setTimeout(() => {
            setIsLoading(false);
        }, 3000);
    };

    return (
        <Modal isOpen style={{ content: { padding: '24px 24px 0' } }}>
            <Image center maxWidth={40} alt={t('basics.noInternet.title')} src={globe_with_meridians} mb={20} />
            <Text type="h5" text={t('basics.noInternet.title')} bold center mb={12} />
            <ErrorSubtitle color="text-secondary" text={t('basics.noInternet.subTitle')} center mb={24} />
            <OutlinedButton isLoading={isLoading} text={t('basics.tryAgain')} onClick={onClick} mb={20} />
        </Modal>
    );
};

export default NoConnectionModal;
