const android = () => {
    return navigator.userAgent.match(/Android/i);
};

const blackBerry = () => {
    return navigator.userAgent.match(/BlackBerry/i);
};

const iOS = () => {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
};

const opera = () => {
    return navigator.userAgent.match(/Opera Mini/i);
};

const windows = () => {
    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
};

const any = () => {
    return Boolean(android() || blackBerry() || iOS() || opera() || windows());
};

export default {
    android,
    blackBerry,
    iOS,
    opera,
    windows,
    any,
};
