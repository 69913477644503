import { styled } from 'styled-components';

import { mediaQueriesNew } from 'theme/mediaQueries';

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    padding-bottom: 40px;

    ${mediaQueriesNew.tablet} {
        padding-bottom: 0;
    }
`;

export const ModalContent = styled.div`
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;
