import { Text, Box } from 'wikr-core-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { selectSubscriptions } from 'store/subscriptions/selectors';
import { discardSubscriptionCancellation } from 'store/subscriptions/actions';

import { sendAnalyticDiscardCancelOfferClick } from 'services/analytics';

import { useDispatch, useModals } from 'hooks';

import OutlinedButton from 'components/OutlinedButton';
import Modal from 'components/Modal';

import * as S from './styled';

const WaitingModal = () => {
    const dispatch = useDispatch();

    const { isRequestSent } = useSelector(selectSubscriptions);

    const { t } = useTranslation();
    const { closeModal } = useModals();

    const onDiscard = useCallback(() => {
        sendAnalyticDiscardCancelOfferClick();
        dispatch(discardSubscriptionCancellation());
    }, [dispatch]);

    return (
        <Modal onClose={closeModal} withCloseButton isOpen>
            <Box paddingTop={20} paddingBottom={20}>
                <Text
                    type="large-text"
                    color="text-main"
                    bold
                    center
                    mb={24}
                    text={t('subscription.cancelDialog.waiting')}
                />
                <Box>
                    <S.Spinner />
                </Box>
                {!isRequestSent && <OutlinedButton text={t('basics.back')} onClick={onDiscard} />}
            </Box>
        </Modal>
    );
};

export default WaitingModal;
