import { DEFAULT_VOLUME, VOLUME_KEY_IN_LS } from 'containers/Workouts/WorkoutPlayer/constants';
import { COMPLEXITY_LEVELS_ARR } from 'constants/workout';
import { FitnessLevel } from 'constants/user';

import isMobile from 'helpers/isMobile';

import { ProgressDataListType } from 'types/workout';
import { IWorkoutExercisesBlock, IWorkoutBlock, IPersonalListDayResponseExtended } from 'types/personalPlan';

export const splitSecondsByHMS = (seconds: number) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    return {
        hrs: hrs < 10 ? `0${hrs}` : hrs,
        mins: mins < 10 ? `0${mins}` : mins,
        secs: secs < 10 ? `0${secs}` : secs,
    };
};

export const fancyTime = (seconds: number, isFullTime?: boolean): string => {
    const { hrs, mins, secs } = splitSecondsByHMS(seconds);
    const fancyMinutesAndSeconds = `${mins}:${secs}`;

    if (Number(hrs) > 0 || isFullTime) {
        return `${hrs}:${fancyMinutesAndSeconds}`;
    }

    return fancyMinutesAndSeconds;
};

export const getInitialVolume = (): number => {
    const valueFromLS = localStorage.getItem(VOLUME_KEY_IN_LS);

    if (valueFromLS) return Number(valueFromLS);

    return isMobile.any() ? 1 : DEFAULT_VOLUME;
};

export const calculateCalories = (exercises: IWorkoutExercisesBlock[], progress: ProgressDataListType): number => {
    const calories = exercises.reduce((totalCalories, { exercise_index, mets }) => {
        if (exercise_index in progress) {
            return totalCalories + (progress[exercise_index].time / 60) * mets;
        }

        return totalCalories;
    }, 0);

    return Number(calories.toFixed());
};

export const increaseComplexityLevel = (currentLevel: FitnessLevel) => {
    let isPossibleToIncreaseLevel, newLevel;

    if (currentLevel === FitnessLevel.Advanced) {
        isPossibleToIncreaseLevel = false;
        newLevel = currentLevel;
    } else {
        const currentLevelIndex = COMPLEXITY_LEVELS_ARR.indexOf(currentLevel);

        isPossibleToIncreaseLevel = true;
        newLevel = COMPLEXITY_LEVELS_ARR[currentLevelIndex + 1];
    }

    return {
        newLevel,
        isPossibleToIncreaseLevel,
    };
};

export const groupExercisesByType = (blocks: IWorkoutBlock[]) => {
    return blocks.reduce((acc: IWorkoutBlock[], current) => {
        const lastIndex = acc.length - 1;

        if (acc.length > 0 && acc[lastIndex].block_type === current.block_type) {
            const groupExercisesByType = [...acc[lastIndex].exercises, ...current.exercises];

            acc[lastIndex] = { ...acc[lastIndex], exercises: groupExercisesByType };
        } else {
            acc.push(current);
        }

        return acc;
    }, []);
};

export const updateExerciseNames = (blocks: IWorkoutBlock[]) => {
    let previousName: string | null = null;
    let count = 1;
    let countIndex = 1;

    return blocks.map((item) => {
        const block_type = item.block_type;

        const updatedExercises = item.exercises.map((exercise) => {
            exercise.exercise_index = countIndex;
            countIndex += 1;
            if (exercise.name === null && previousName) {
                count += 1;

                return { ...exercise, name: `${previousName} ${count}`, block_type };
            } else {
                previousName = exercise.name;
                count = 1;

                return { ...exercise, block_type };
            }
        });

        return { ...item, exercises: updatedExercises };
    });
};

export const isLastDayOfThePlan = (currentDay: number, totalDays: number): boolean => currentDay === totalDays;

export const isWholePlanCompleted = (workouts: IPersonalListDayResponseExtended[]): boolean =>
    Boolean(workouts[workouts.length - 1].completion_date);
