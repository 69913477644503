import styled from 'styled-components';

import { color } from 'theme/selectors';

export const ProgressBoard = styled.ul`
    background-color: ${color('surface-default')};
    display: flex;
    flex-direction: row;
    align-items: center;
    filter: drop-shadow(0px 4px 16px rgba(122, 122, 122, 0.1));
`;

export const ProgressBoardItem = styled.li`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-right: 1px solid ${color('border-default')};
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    min-height: 102px;
    &:last-child {
        border-right: none;
    }
`;

export const ProgressBoardItemNumber = styled.span`
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 4px;
    display: inline-block;
`;
