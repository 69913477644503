import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { getToken } from 'api/utils/tokenManagement';

import { initFirebase } from 'services/Firebase/initFirebase';

import { useUserLoggedIn } from 'hooks/user';

import Sidebar from 'components/Sidebar';
import Header from 'components/Header/Header';

import * as S from './styled';

import { LayoutProps } from '../types';

import routes from '../routes';

const CabinetLayout = ({ children, titleKey }: LayoutProps) => {
    const { t } = useTranslation();

    const isUserLoggedIn = useUserLoggedIn();

    useEffect(() => {
        document.title = t(titleKey);
    }, [titleKey]);

    useEffect(() => {
        if (isUserLoggedIn) {
            initFirebase();
        }
    }, [isUserLoggedIn]);

    if (!isUserLoggedIn) return <Navigate to={routes.PUBLIC.LOGIN.path} />;

    const token = getToken();

    return (
        <S.BodyContainer>
            <Header noControls={Boolean(token)} />

            <S.MainContainer>
                <Sidebar />
                {children}
            </S.MainContainer>
        </S.BodyContainer>
    );
};

export default CabinetLayout;
