import { ActionEventAction, ElementEventAction, EventTriggerFixed } from 'wikr-core-analytics';

import { trackClick, trackEventFixed } from './mainTrackers';

const LOGIN_SCREEN_ID = 'log_in';

export const sendAnalyticLoginEmailClick = () => {
    trackClick({
        screenIdParam: LOGIN_SCREEN_ID,
        elementEvent: ElementEventAction.input,
        actionEvent: ActionEventAction.email,
    });
};

export const sendAnalyticLoginPasswordClick = () => {
    trackClick({
        screenIdParam: LOGIN_SCREEN_ID,
        elementEvent: ElementEventAction.input,
        actionEvent: ActionEventAction.password,
    });
};

export const sendAnalyticLoginButtonClick = () => {
    trackClick({
        screenIdParam: LOGIN_SCREEN_ID,
        elementEvent: ElementEventAction.text,
        actionEvent: ActionEventAction.headerLogin,
    });
};

export const sendAnalyticResetPasswordClick = () => {
    trackClick({
        screenIdParam: LOGIN_SCREEN_ID,
        elementEvent: ElementEventAction.text,
        actionEvent: ActionEventAction.resetPassword,
    });
};

export const sendAnalyticLoginSuccess = () => {
    trackEventFixed(EventTriggerFixed.loginSuccess);
};

export const sendAnalyticLoginError = (eventLabel?: string) => {
    trackEventFixed(EventTriggerFixed.loginError, eventLabel);
};
