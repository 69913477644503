import i18next, { TFunction } from 'i18next';

import { SUBSCRIPTION_STATES } from 'constants/subscriptions';
import { PRODUCT_CODES } from 'constants/product';
import { CURRENCY_SIGN_MAP } from 'constants/currency';

import { getPriceWithDecimals } from 'helpers/prices';

import { SuccessModalPropsType } from 'components/Modals/Subscriptions/SuccessModal/types';

import slightly_smiling_face from 'assets/emoji/slightly_smiling_face.webp';
import raised_hands from 'assets/emoji/raised_hands.webp';
import error from 'assets/emoji/exclamation2.svg';
import exclamation from 'assets/emoji/exclamation.webp';
import check_mark from 'assets/emoji/check_mark.webp';

import { IDiscountSubscription, ISubscription } from 'types/subscription';
import { Product } from 'types/product';

export const isMainSubscription = (productCode: ISubscription['product_code']) =>
    productCode === PRODUCT_CODES.APP_FULL_ACCESS || productCode === PRODUCT_CODES.MAIN;

export const sortMainSubscriptionFirst = (subscriptions: Array<ISubscription>): Array<ISubscription> =>
    subscriptions.sort((a) => (isMainSubscription(a.product_code) ? -1 : 0));

export const getSubscriptionsByStatus = (subscriptions: Array<ISubscription>) => {
    const activeSubscriptions: ISubscription[] = [];
    const canceledSubscriptions: ISubscription[] = [];
    const expiredSubscriptions: ISubscription[] = [];

    const sortedSubscriptions = sortMainSubscriptionFirst(subscriptions);

    sortedSubscriptions.forEach((e) => {
        const isCancelled = e.state === SUBSCRIPTION_STATES.CANCELLED || Boolean(e.cancelled_at);
        const isActive = e.state === SUBSCRIPTION_STATES.ACTIVE;

        if (isCancelled && isActive) canceledSubscriptions.push(e);
        if ((isCancelled && !isActive) || e.state === SUBSCRIPTION_STATES.CHARGEBACK) expiredSubscriptions.push(e);
        if ((isActive || e.state === SUBSCRIPTION_STATES.REDEMPTION) && !Boolean(e.cancelled_at))
            activeSubscriptions.push(e);
    });

    return { sortedSubscriptions, activeSubscriptions, canceledSubscriptions, expiredSubscriptions };
};

export const getSubscriptionData = (
    t: TFunction,
    { subscription_period, amount, currency }: IDiscountSubscription | Product
) => ({
    period: subscription_period,
    type: t('subscription.type.subscription'),
    price: getPriceWithDecimals({ price: amount, currency }),
});

export const getChangeSubscriptionSuccessModalData = ({
    amount,
    currency,
    subscription_period,
}: IDiscountSubscription): SuccessModalPropsType => ({
    title: i18next.t('subscription.successModal.canceled.title'),
    subtitle: i18next.t('subscription.successModal.newSubscription.subtitle', {
        period: subscription_period,
        currencySign: CURRENCY_SIGN_MAP[currency],
        price: getPriceWithDecimals({ price: amount, currency }),
    }),
    buttonText: i18next.t('basics.continue'),
    image: {
        src: raised_hands,
    },
    withCloseButton: true,
    dataLocators: {
        title: 'unsubscribeSuccessfulTitle',
        subTitle: 'unsubscribeSuccessfulDescription',
        body: 'unsubscribeSuccessfulBlock',
    },
});

export const getCancelSubscriptionSuccessModalData = (): SuccessModalPropsType => ({
    title: i18next.t('subscription.successModal.canceled.title'),
    subtitle: i18next.t('subscription.successModal.cancelSubscription.subtitle'),
    buttonText: i18next.t('basics.continue'),
    image: {
        src: slightly_smiling_face,
    },
    withCloseButton: true,
    dataLocators: {
        title: 'unsubscribeSuccessfulTitle',
        subTitle: 'unsubscribeSuccessfulDescription',
        body: 'unsubscribeSuccessfulBlock',
    },
});

export const getErrorModalDataOld = (title: string) => ({
    title: i18next.t(title),
    image: exclamation,
});

export const getErrorModalData = ({ title, subtitle }: { title: string; subtitle?: string }) => ({
    title: i18next.t(title),
    img: { src: error },
    ...(subtitle && { subtitle: i18next.t(subtitle) }),
});

export const getRestoreSuccessModalData = ({
    amount,
    currency,
    subscription_period,
}: Product): SuccessModalPropsType => ({
    title: i18next.t('subscription.successRestoreModal.title'),
    image: {
        src: check_mark,
        mt: false,
    },
    subtitle: i18next.t('subscription.restoreModal.subscriptionPeriod', {
        period: subscription_period,
        currencySign: CURRENCY_SIGN_MAP[currency],
        price: getPriceWithDecimals({ price: amount, currency }),
    }),
    buttonText: i18next.t('basics.continue'),
    withRoundedCorners: true,
    dataLocators: {
        title: 'restoreSuccessfulTitle',
        subTitle: 'restoreSuccessfulDescription',
        body: 'restoreSuccessfulBlock',
    },
});
