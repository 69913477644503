import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import * as S from './styled';

export const TagBeta = () => {
    const { t } = useTranslation();

    return (
        <S.Container>
            <Text text={t('basics.beta').toUpperCase()} type="small-text" bold color="decorative-c" />
        </S.Container>
    );
};
