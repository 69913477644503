import { Button, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import { sendAnalyticKeepSubscriptionClick } from 'services/analytics';

import { useCancelSubscriptionFlow, useModals } from 'hooks';

import OverlineButton from 'components/OverlineButton';
import { ModalName } from 'components/Modals/types';
import Modal from 'components/Modal/Modal';

import * as S from './styled';

import warning from 'assets/img/warning.svg';

import { useGetTexts } from './config';

const ConfirmationModal = () => {
    const { t } = useTranslation();

    const { openModal, closeModal } = useModals();

    const { discardCancellation } = useCancelSubscriptionFlow();

    const onClose = () => {
        discardCancellation();
        closeModal();
        sendAnalyticKeepSubscriptionClick();
    };

    const onSubmit = () => {
        openModal(ModalName.CancellationReasonModal);
    };

    const { title, subtitle } = useGetTexts();

    return (
        <Modal paddingY={16} isOpen onClose={closeModal} shouldCloseOnOverlayClick>
            <div data-locator="cancelSubModal">
                <S.Img src={warning} alt="warning" />
                <Text type="h5" center text={title} mb={12} />
                <Text center color="text-secondary" text={subtitle} mb={24} />

                <Button
                    dataLocator="cancelSubTrueBtn"
                    onClick={onSubmit}
                    text={t('subscription.discountOfferModal.headerTitle')}
                    mb={20}
                />
                <OverlineButton
                    dataLocator="cancelSubFalseBtn"
                    onClick={onClose}
                    text={t('subscription.cancelDialog.discard')}
                    mb={4}
                />
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
