export enum YogaType {
    Classic = 'classic',
    ChairYoga = 'chair_yoga',
    WallPilates = 'wall_pilates',
    SofaYoga = 'sofa_yoga',
}

export enum WorkoutFocus {
    Balance = 'balance',
    Strength = 'strength',
    Flexibility = 'flexibility',
}
