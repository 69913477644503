import { delay, put, take, takeLatest, fork, cancel, cancelled, all, call } from 'redux-saga/effects';
import { Task } from 'redux-saga';
import { CancelledEffect } from '@redux-saga/core/effects';

import { IResultResponse } from 'api/types/response';
import api from 'api';

import { openModal } from 'store/modals/actions';

import { cancelSubscription__Old, fetchSubscriptions, updateSubscription__Old } from './actions';
import { notifySuccess } from '../notifications/actions';

import { DISCARD_SUBSCRIPTION_CANCELLATION, CANCEL_SUBSCRIPTION_OLD, UPDATE_SUBSCRIPTION_OLD } from './actionTypes';

import { DISCOUNT_APPLIED_ERROR, DISCOUNT_APPLIED_SUCCESS } from 'constants/analytics';

import { sendAnalyticDiscountAppliedResult, sendAnalyticFrontCancelSubscriptionRequest } from 'services/analytics';

import {
    getCancelSubscriptionSuccessModalData,
    getChangeSubscriptionSuccessModalData,
    getErrorModalDataOld,
} from 'helpers/subscriptions';

import { ModalName } from 'components/Modals/types';

import { ICancelSubscriptionOld } from 'types/subscription';

function* makeSubscriptionCancelling({ payload }: ReturnType<typeof cancelSubscription__Old>) {
    try {
        const workerTask: Task = yield fork(callUnsubscribe, payload);

        yield take(DISCARD_SUBSCRIPTION_CANCELLATION);
        yield cancel(workerTask);
    } catch (error) {
        payload.onError();
    }
}

function* callUnsubscribe(payload: ICancelSubscriptionOld) {
    try {
        yield delay(3000);

        sendAnalyticFrontCancelSubscriptionRequest();

        const response: IResultResponse = yield call(api.subscriptions.unsubscribe, {
            external_id: payload.externalId,
        });

        if (!response.result) {
            throw new Error('Subscription is not cancelled');
        }

        yield put(fetchSubscriptions());
        yield put(openModal(ModalName.SuccessModal, getCancelSubscriptionSuccessModalData()));

        payload.onSuccess();
    } catch (error) {
        yield put(
            openModal(
                ModalName.CancelSubscriptionErrorModalOld,
                getErrorModalDataOld('subscription.cancel.error.title')
            )
        );
        payload.onError();
    } finally {
        const isCancelled: CancelledEffect = yield cancelled();

        if (isCancelled) {
            yield put(notifySuccess('subscription.cancellation.response.abort'));
            payload.onCancel();
        }
    }
}

function* changeCurrentSubscription({ payload }: ReturnType<typeof updateSubscription__Old>) {
    try {
        yield api.subscriptions.updateSubscription(payload);

        yield put(fetchSubscriptions());
        yield put(openModal(ModalName.SuccessModal, getChangeSubscriptionSuccessModalData(payload.product)));

        yield call(sendAnalyticDiscountAppliedResult, DISCOUNT_APPLIED_SUCCESS, payload.product.name);
    } catch (error) {
        yield put(
            openModal(
                ModalName.ChangeSubscriptionErrorModalOld,
                getErrorModalDataOld('subscription.changeSubscription.error.title')
            )
        );

        yield call(
            sendAnalyticDiscountAppliedResult,
            DISCOUNT_APPLIED_ERROR,
            'Subscription plan not updated due to a technical issue'
        );
    }
}

export default function* watchSubscriptions__Old() {
    yield all([
        takeLatest(CANCEL_SUBSCRIPTION_OLD, makeSubscriptionCancelling),
        takeLatest(UPDATE_SUBSCRIPTION_OLD, changeCurrentSubscription),
    ]);
}
