import { Action } from 'types/commonInterfaces';

export type LoadingState = { [key: string]: boolean };

export const errorRegExp = /(.*)_(ERROR)$/;
export const successRegExp = /(.*)_(SUCCESS)$/;

export default (state: LoadingState = {}, action: Action) => {
    const errorMatches = errorRegExp.exec(action.type);
    const successMatches = successRegExp.exec(action.type);

    if (successMatches) {
        const [, requestName] = successMatches;

        return {
            ...state,
            [requestName]: null,
        };
    }

    if (errorMatches) {
        const [, requestName, requestState] = errorMatches;

        return {
            ...state,
            [requestName]: requestState !== 'ERROR' ? null : action.payload,
        };
    }

    return state;
};
