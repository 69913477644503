import { Text } from 'wikr-core-components';

import * as S from './styled';

import { FitnessLevelCardProps } from './types';

const FitnessLevelCard = ({ title, description, isActive, icon: Icon, onClick, id }: FitnessLevelCardProps) => (
    <S.CardWrapper isActive={isActive} onClick={() => onClick(id)} data-locator="fitnessLevelCard">
        <div>
            <Text text={title} mb={4} bold />
            <S.Description text={description} type="small-text" color="text-secondary" />
        </div>
        <Icon />
    </S.CardWrapper>
);

export default FitnessLevelCard;
