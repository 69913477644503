import { getExperimentsByEnv } from 'services/Firebase/helpers';

export const changePathNameToScreenId = (pathName = window.location.pathname) => {
    const screenId = pathName.replace(/^\/|\/$/g, '').replace(/(\/([a-z]{2})\/)|\//g, '_');

    return screenId || 'log_in';
};

export const getAbTestName = async () => {
    const envBasedExperiments = await getExperimentsByEnv();

    const abTestName = Object.entries(envBasedExperiments)
        .map((entry) => entry.join('__'))
        .join(', ');

    return abTestName;
};
