import { Text, Box, Image } from 'wikr-core-components';
import useI18next from 'services/i18n';
import { ERROR_LEVELS, SENTRY_CABINET } from 'sentry-utils';
import { useRouteError } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import sentry from 'services/Sentry/SentryInstance';
import { IError } from 'services/Sentry/interfaces';

import OutlinedButton from 'components/OutlinedButton';
import ContactUsLink from 'components/ContactUsLink';

import * as S from './styled';

import face_with_spiral_eyes from 'assets/emoji/face_with_spiral_eyes.webp';

const ErrorBoundary = () => {
    // useI18next() is necessary to fix error: You will need to pass in an i18next instance by using initReactI18next
    useI18next();

    const { t } = useTranslation();

    const error = useRouteError();

    useEffect(() => {
        sentry.logError(error as IError, SENTRY_CABINET, ERROR_LEVELS.CRITICAL);
    }, [error]);

    return (
        <S.ErrorBoundaryWrapper>
            <Box>
                <Image center maxWidth={40} alt={t('basics.appError')} src={face_with_spiral_eyes} mb={20} />
                <Text type="h5" text={t('basics.appError')} bold center mb={12} />
                <Text color="text-secondary" text={t('basics.appError.subTitle')} center mb={32} />
                <OutlinedButton text={t('basics.tryAgain')} onClick={() => window.location.reload()} mb={20} />
                <ContactUsLink subject={t('basics.errorMessage')} text="basics.contactSupport" />
            </Box>
        </S.ErrorBoundaryWrapper>
    );
};

export default ErrorBoundary;
