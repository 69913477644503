import { Button, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';

import { selectCurrentUser } from 'store/user/selectors';
import { UPDATE_USER } from 'store/user/actionTypes';
import { updateUserRequest } from 'store/user/actions';
import { createProcessingSelector } from 'store/loading/selectors';

import { ProblemZone } from 'constants/user';

import { useDispatch, useModals, useSelector } from 'hooks';

import { checkZonesEqual, filterSelectedZones } from './helpers';

import { Pill } from 'components/Pill';
import OverlineButton from 'components/OverlineButton';
import Modal from 'components/Modal/Modal';

import * as S from './styled';

const ProblemZonesModal = () => {
    const dispatch = useDispatch();

    const { problem_zones } = useSelector(selectCurrentUser);
    const { t } = useTranslation();
    const { closeModal } = useModals();

    const [selectedZones, setSelectedZones] = useState<ProblemZone[]>(problem_zones ?? []);

    const isLoading: boolean = useSelector(createProcessingSelector([UPDATE_USER]));

    const areZonesEqual = useMemo(
        () => checkZonesEqual(selectedZones, problem_zones ?? []),
        [selectedZones, problem_zones]
    );

    const isSaveButtonDisabled = !selectedZones.length || areZonesEqual;

    const handleSelectZone = useCallback(
        (zone: ProblemZone) => {
            const filteredZones = filterSelectedZones(selectedZones, zone);

            setSelectedZones(filteredZones);
        },
        [selectedZones]
    );

    const onSave = () => {
        dispatch(
            updateUserRequest({
                payload: {
                    problem_zones: selectedZones,
                },
                onSuccess: closeModal,
            })
        );
    };

    return (
        <Modal
            onClose={closeModal}
            withRoundedCorners={false}
            dataLocator="problemAreaModal"
            mobileFullScreen
            isOpen
            shouldCloseOnOverlayClick
        >
            <S.ContentWrapper data-locator="problemAreasModal">
                <Text type="h4" center text={t('account.planSettings.problemAreas')} />
                <S.ModalContent data-locator="pillsWrapper">
                    {Object.values(ProblemZone).map((zone) => (
                        <Pill<ProblemZone>
                            onClick={handleSelectZone}
                            id={zone}
                            title={t(`basics.user.problemZone.${zone}--dynamicKey`)}
                            key={zone}
                            isActive={selectedZones.includes(zone)}
                        />
                    ))}
                </S.ModalContent>
                <div>
                    <Button
                        dataLocator="save"
                        disabled={isSaveButtonDisabled}
                        mb={20}
                        text={t('basics.save')}
                        onClick={onSave}
                        isLoading={isLoading}
                    />
                    <OverlineButton dataLocator="cancelBtn" onClick={closeModal} text={t('basics.cancel')} />
                </div>
            </S.ContentWrapper>
        </Modal>
    );
};

export default ProblemZonesModal;
