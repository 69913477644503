import { Button, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { SUBSCRIPTION_RENEWAL_FAILED } from 'constants/analytics';

import { trackScreenLoad } from 'services/analytics/trackers/mainTrackers';
import { sendAnalyticChangePaymentMethodButtonClick } from 'services/analytics';

import { useModals } from 'hooks';

import { getFullRemarketingFlow } from 'helpers/subscriptions/changePaymentMethod';

import OutlinedButton from 'components/OutlinedButton';
import Modal from 'components/Modal/Modal';

import { ISubscription } from 'types/subscription';

export const CLOSE_EVENT_ACTION = 'cancel';
export const CHOOSE_PLAN_EVENT_ACTION = 'choose_plan';

export type ButtonEventAction = typeof CLOSE_EVENT_ACTION | typeof CHOOSE_PLAN_EVENT_ACTION;

const RestoreSubscriptionErrorModal = () => {
    const { t } = useTranslation();

    const { closeModal, modalProps } = useModals();

    const {
        product: { subscription_period, name, id },
        external_id,
    } = modalProps as ISubscription;

    useEffect(() => {
        trackScreenLoad(SUBSCRIPTION_RENEWAL_FAILED, {
            subscription_id: external_id,
            period: subscription_period,
            tariff: name,
            content_id: id,
        });
    }, []);

    const sendAnalyticsClick = (eventAction: ButtonEventAction) => {
        sendAnalyticChangePaymentMethodButtonClick(
            SUBSCRIPTION_RENEWAL_FAILED,
            {
                subscription_id: external_id,
                period: subscription_period,
                tariff: name,
                content_id: id,
            },
            eventAction
        );
    };

    const handleChoosePlan = () => {
        const redirectLink = getFullRemarketingFlow();

        sendAnalyticsClick(CHOOSE_PLAN_EVENT_ACTION);

        window.open(redirectLink, '_self');
    };

    const handleModalClose = () => {
        sendAnalyticsClick(CLOSE_EVENT_ACTION);
        closeModal();
    };

    return (
        <Modal paddingY={16} isOpen onClose={closeModal} shouldCloseOnOverlayClick>
            <div data-locator="restoreSubModal">
                <Text type="h5" center text={t('subscription.errorRestoreModal.title')} mb={12} />
                <Text center color="text-secondary" text={t('subscription.errorRestoreModal.subtitle')} mb={24} />
                <Button
                    dataLocator="choosePlanBtn"
                    onClick={handleChoosePlan}
                    text={t('subscription.restoreModal.error.choosePlan')}
                    mb={20}
                />
                <OutlinedButton dataLocator="cancelBtn" onClick={handleModalClose} text={t('basics.cancel')} mb={4} />
            </div>
        </Modal>
    );
};

export default RestoreSubscriptionErrorModal;
