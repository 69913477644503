import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';

import { setMainSessionId } from 'store/session/actions';

import { useDispatch } from 'hooks/store';

import { uuidv4 } from 'helpers/utils';

import usePlanSessionTracker from './usePlanSessionTracker';
import useMainSessionTracker from './useMainSessionTracker';

const useSessionTrackers = () => {
    const location = useLocation();
    const isFirstRender = useRef(true);

    const dispatch = useDispatch();

    const { endMainSessionHandler, startMainSessionHandler } = useMainSessionTracker();

    const { endPlanSessionHandler, startPlanSessionHandler, onPlanRouteChange } = usePlanSessionTracker();

    useEffect(() => {
        const onSessionStart = () => {
            dispatch(setMainSessionId(uuidv4()));

            startMainSessionHandler();
            startPlanSessionHandler();
        };

        const onSessionEnd = () => {
            endMainSessionHandler();
            endPlanSessionHandler();

            dispatch(setMainSessionId(''));
        };

        window.addEventListener('beforeunload', onSessionEnd);
        window.addEventListener('focus', onSessionStart);
        window.addEventListener('blur', onSessionEnd);

        return () => {
            window.removeEventListener('beforeunload', onSessionEnd);
            window.removeEventListener('focus', onSessionStart);
            window.removeEventListener('blur', onSessionEnd);
        };
    }, [endMainSessionHandler, endPlanSessionHandler, startMainSessionHandler, startPlanSessionHandler, dispatch]);

    useEffect(() => {
        if (!isFirstRender.current) {
            onPlanRouteChange();
        } else {
            isFirstRender.current = false;
        }
    }, [location.pathname, onPlanRouteChange]);
};

export default useSessionTrackers;
