import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import * as S from '../styled';

import { SidebarLinkButtonProps } from '../types';

const SidebarLinkButton = ({ isActive, title }: SidebarLinkButtonProps) => {
    const { t } = useTranslation();

    return (
        <S.NavButton>
            <Text bold={isActive} type="large-text" color="text-main" text={t(title)} />
        </S.NavButton>
    );
};

export default SidebarLinkButton;
