import { hotjar } from 'react-hotjar';

import config from 'config';

const HOTJAR_NUMBER_ID = Number(config.HOTJAR_ID);
const HOTJAR_VERSION = Number(config?.HOTJAR_VERSION);

export const initHotjar = () => {
    HOTJAR_NUMBER_ID && hotjar.initialize(HOTJAR_NUMBER_ID, HOTJAR_VERSION);
};
