import { PersonalPlanPropsType } from 'containers/Workouts/PersonalPlan/types';

import { WorkoutState } from 'types/workout';
import { IPersonalListDayResponse, IPersonalListDayResponseExtended } from 'types/personalPlan';

import dayjs from 'dayjs';

const userData = dayjs();

type IAddWorkoutStatus = ({
    workouts,
    currentDay,
}: {
    workouts: IPersonalListDayResponse[];
    currentDay: PersonalPlanPropsType['currentDay'];
}) => {
    workouts: IPersonalListDayResponseExtended[];
    availableCurrentDay: PersonalPlanPropsType['currentDay'];
};

export const addWorkoutStatus: IAddWorkoutStatus = ({ workouts, currentDay }) => {
    let availableCurrentDay = currentDay;

    const workoutsWithStatus = workouts.map((workout) => {
        const workoutDay = workout.day_number;
        const prevWorkoutDay = currentDay - 1;
        const prevWorkout = workouts.find((item) => item.day_number === prevWorkoutDay);
        const isPrevWorkoutWasToday = prevWorkout?.completion_date
            ? userData.isSame(prevWorkout.completion_date, 'day')
            : false;

        const isAvailableToday = workoutDay === currentDay && !isPrevWorkoutWasToday;
        const isNotAvailableToday = (workoutDay === currentDay && isPrevWorkoutWasToday) || workoutDay > currentDay;
        const isCompletedToday = workoutDay === prevWorkout?.day_number;

        if (isAvailableToday) {
            availableCurrentDay = workout.day_number;

            return { ...workout, completeness: WorkoutState.availableToday };
        }
        if (isNotAvailableToday) {
            return { ...workout, completeness: WorkoutState.notAvailableToday };
        }
        if (isCompletedToday) {
            availableCurrentDay = isPrevWorkoutWasToday ? workout.day_number : currentDay;

            return {
                ...workout,
                completeness: isPrevWorkoutWasToday ? WorkoutState.completedToday : WorkoutState.completedBeforeToday,
            };
        }

        return { ...workout, completeness: WorkoutState.completedBeforeToday };
    });

    return {
        workouts: workoutsWithStatus,
        availableCurrentDay,
    };
};
