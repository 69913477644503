import { Currencies } from 'types/currency';

export const CURRENCY_SIGN_MAP: { [key in keyof typeof Currencies]: string } = {
    USD: '$',
    EUR: '€',
    GBP: '£',
    BRL: 'R$',
    MXN: 'M$',
    ARS: 'ARS',
    CLP: 'CLP',
    COP: 'COL$',
    PEN: 'S/',
};
