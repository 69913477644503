import { useTheme } from 'styled-components';
import ContentLoader from 'react-content-loader';

import { SkeletonContainer } from './styled';

import { ISkeleton } from './types';

import 'react-loading-skeleton/dist/skeleton.css';

const WIDTH = 400;
const HEIGHT = 400;

const Skeleton = ({ width, height, borderRadius, mb, marginLeft }: ISkeleton) => {
    const theme = useTheme();

    return (
        <SkeletonContainer
            width={width}
            height={height}
            mb={mb}
            marginLeft={marginLeft}
            borderRadius={borderRadius || Number(theme['border-radius-medium'])}
        >
            <ContentLoader foregroundColor={theme['surface-main']} backgroundColor={theme['surface-default']} animate>
                <rect x={0} y={0} width={WIDTH} height={HEIGHT} />
            </ContentLoader>
        </SkeletonContainer>
    );
};

export default Skeleton;
