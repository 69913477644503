// user
export interface UserData {
    date_of_birth: string;
    email: string;
    gender: string;
    height: number;
    weight: number;
    target_weight: number;
    is_mobile_user: boolean | null;
}

export interface UserUpdateData extends Partial<UserData> {
    country?: string;
    last_name?: string;
    units?: string;
    sendpulse_mailing_active?: boolean;
    password?: string;
    phone?: string;
    name?: string;
}

export type ChangePasswordType = {
    old_password: string;
    new_password: string;
};

export interface UserInfoResponse extends UserData {
    user_id: number;
    token: string;
    name: null | string;
    surename: null | string;
    language: string;
    country: null | string;
    product_list: null | {
        [product: string]: boolean;
    };
    units?: string;
    localization: string;
    target_zone: string[];
    test_name?: string;
    ip_address: string;
    fitness_level: string;
    phone?: string;
}

export interface SignInData {
    password: string;
    email: string;
}

// other
export interface DeepLinkData {
    url: string;
}

export interface HashedEmailResp {
    data: string;
}

export enum UnitTypes {
    Imperial = 'imperial',
    Metric = 'metric',
}

export enum UserFeature {
    AllFeatures = 'all_features',
    Plan = 'plan',
    WorkoutBuilder = 'workout_builder',
    GeneralSeries = 'general_series',
    Face = 'face',
    Fatburn = 'fatburn',
    Sevenmin = 'sevenmin',
    Belly = 'belly',
    AllGuides = 'all_guides',
}
