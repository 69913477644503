import { Box } from 'wikr-core-components';
import styled, { keyframes } from 'styled-components';

const loaderPulseAnimation = keyframes`
    0% {
        transform: translate(-50%, -50%) scale(1);
    }

    50% {
        transform: translate(-50%, -50%) scale(1.4);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
    }
`;

export const Container = styled(Box)`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    > * {
        width: 40px;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: ${loaderPulseAnimation} 1s infinite;

        * {
            width: 100%;
        }
    }
`;
