import { getToken } from 'api/utils/tokenManagement';

import { CHANGE_PAYMENT_METHOD_QUERY_NAME, REMARKETING_FLOW_LINK } from 'constants/subscriptions/changePaymentMethod';

type GetFullRemarketingFlowType = (baseUrl?: string | undefined) => string;

// CHANGE_PAYMENT_METHOD_QUERY_NAME is added to recognize on onboarding where to redirect user after changing payment method
export const getFullRemarketingFlow: GetFullRemarketingFlowType = (baseUrl = REMARKETING_FLOW_LINK) => {
    const token = getToken();

    return `${baseUrl}&token=${token}&${CHANGE_PAYMENT_METHOD_QUERY_NAME}=true`;
};
