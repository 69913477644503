import { Text } from 'wikr-core-components';
import { css, styled } from 'styled-components';

import { borderRadius, color } from 'theme/selectors';

export const CardWrapper = styled.div<{ isActive?: boolean }>`
    display: flex;
    gap: 8px;
    align-items: center;
    background: ${color('surface-default')};
    border-radius: ${borderRadius('medium')};
    padding: 16px 20px;
    border: 2px solid
        ${({ isActive }) =>
            css`
                ${color(isActive ? 'primary-default' : 'surface-default')}
            `};
    cursor: pointer;
`;

export const Description = styled(Text)`
    max-width: 263px;
`;
