/* eslint-disable max-lines */
import { Image, Button, Text } from 'wikr-core-components';
import styled from 'styled-components';

import { color } from 'theme/selectors';
import { mediaQueriesNew } from 'theme/mediaQueries';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: fixed;
    z-index: 6;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: ${color('surface-main')};
`;

export const CloseBtn = styled.button`
    position: absolute;
    right: 12px;
    top: 12px;
    &:hover path {
        fill: ${color('on-primary')};
    }
    ${mediaQueriesNew.tablet} {
        right: 24px;
        top: 24px;
    }
    ${mediaQueriesNew.desktop} {
        right: 40px;
    }
`;

export const TopWrapper = styled.div`
    overflow: auto;
    flex-grow: 1;
    flex-basis: 0;
`;

export const TopWrapperContent = styled.div`
    max-width: 384px;
    margin: 0 auto;
    flex-grow: 1;
    padding: 44px 16px 32px;
    ${mediaQueriesNew.tablet} {
        padding-top: 124px;
        padding-bottom: 124px;
    }

    ${mediaQueriesNew.desktop} {
        padding-top: 140px;
        padding-bottom: 140px;
        display: flex;
        max-width: 100%;
        justify-content: space-evenly;
        align-items: center;
        > .lazyload-wrapper {
            order: 2;
        }
    }
`;
export const TopWrapperRightCol = styled(Image)`
    width: 140px;
    margin-bottom: 24px;
    ${mediaQueriesNew.tablet} {
        width: 350px;
    }
    ${mediaQueriesNew.desktop} {
        margin-bottom: 0;
    }
    ${mediaQueriesNew.largeDesktop} {
        width: 450px;
    }
`;

export const TopWrapperLeftCol = styled.div`
    text-align: center;
    ${mediaQueriesNew.desktop} {
        width: 400px;
        text-align: left;
    }
    ${mediaQueriesNew.largeDesktop} {
        width: 414px;
    }
`;

export const TopWrapperLeftColText = styled(Text)`
    ${mediaQueriesNew.desktop} {
        font-size: 40px;
        line-height: 52px;
        margin-bottom: 32px;
    }
`;

export const TopWrapperSubtitle = styled.div`
    display: flex;
    margin-bottom: 8px;
    justify-content: center;
    align-items: center;
    ${mediaQueriesNew.desktop} {
        justify-content: flex-start;
    }
`;

export const TopWrapperSubtitleText = styled(Text)`
    ${mediaQueriesNew.desktop} {
        font-size: 21px;
        line-height: 32px;
    }
`;

export const TopWrapperSubtitleIcon = styled(Image)`
    margin-right: 8px;
    ${mediaQueriesNew.desktop} {
        max-width: 24px;
    }
`;

export const BottomWrapper = styled.div`
    flex-shrink: 0;
`;

export const BottomWrapperBody = styled.div`
    background-color: ${color('surface-default')};
    padding: 16px 16px 0;
    ${mediaQueriesNew.tablet} {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        padding: 20px 32px 0;
    }
    ${mediaQueriesNew.desktop} {
        padding: 32px 64px 20px;
    }
    ${mediaQueriesNew.largeDesktop} {
        padding-left: 96px;
        padding-right: 96px;
    }
`;

export const BottomWrapperBodyCol = styled.div`
    &:nth-child(1) {
        ${mediaQueriesNew.tablet} {
            flex-shrink: 0;
        }
    }
    &:nth-child(2) {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: flex-end;
        ${mediaQueriesNew.desktop} {
            flex-direction: row;
            justify-content: flex-end;
            gap: 12px;
            align-items: flex-start;
        }
    }
`;

export const BottomWrapperBodyTitle = styled.div`
    display: flex;
    margin-bottom: 4px;
    gap: 10px;
    ${mediaQueriesNew.tablet} {
        margin-bottom: 8px;
    }
`;

export const BottomWrapperHeader = styled.div`
    background: ${color('surface-secondary')};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 16px;
    font-size: 12px;
`;

export const BottomWrapperKeepLevelBtn = styled(Button)`
    border: 1px solid ${color('border-default')};
    ${mediaQueriesNew.tablet} {
        max-width: 343px;
        flex-shrink: 0;
    }
`;
export const BottomWrapperIncreaseLeaveBtn = styled(Button)`
    border: 1px solid transparent;
    ${mediaQueriesNew.tablet} {
        max-width: 343px;
        flex-shrink: 0;
    }
`;
