import * as actionTypes from './actionTypes';

export const notify = (message: string) => {
    return {
        type: actionTypes.DISPLAY_DEFAULT_NOTIFICATION,
        payload: message,
    } as const;
};

export const notifySuccess = (message: string) => {
    return {
        type: actionTypes.DISPLAY_SUCCESS_NOTIFICATION,
        payload: message,
    } as const;
};

export const notifyError = (message: string) => {
    return {
        type: actionTypes.DISPLAY_ERROR_NOTIFICATION,
        payload: message,
    } as const;
};
