import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';

import { store } from 'store/configureStore';

import Sentry from 'services/Sentry/SentryInstance';

import reportWebVitals from './reportWebVitals';
import App from './App';

Sentry.init();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

reportWebVitals(() => {});
