export const PRODUCT_CODES = {
    APP_FULL_ACCESS: 'app_full_access',
    MAIN: 'main',
    APP_VIP_SUPPORT: 'vip_support',
    APP_GUIDE_WEIGHT_LOSS: 'weight_loss_gd',
    APP_GUIDE_FAT_BURNING: 'fat_burning_gd',
    APP_GUIDE_ALL: 'fat_burning_gd__weight_loss_gd',
    APP_GUIDE_WEIGHT_LOSS_FULL: 'weight_loss_gd_full',
    APP_GUIDE_FAT_BURNING_FULL: 'fat_burning_gd_full',
    APP_GUIDE_ALL_FULL: 'fat_burning_gd__weight_loss_gd_full',
};
