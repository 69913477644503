import { Currencies } from 'types/currency';

export const getPriceWithDecimals: ({
    price,
    decimals,
}: {
    price: number;
    decimals?: number;
    currency?: Currencies;
}) => string = ({ price, decimals = 2, currency }) => {
    if (currency === Currencies.CLP) return String(price);

    const priceValue = price / 100;

    return priceValue.toFixed(decimals);
};
