import { useCallback } from 'react';

import { selectModalProps } from 'store/modals/selectors';
import { closeModal as closeModalAction, openModal as openModalAction } from 'store/modals/actions';

import { useDispatch, useSelector } from 'hooks';

import { ModalName, ModalPropsType } from 'components/Modals/types';

// M is current modalId
const useModals = <M extends ModalName>() => {
    const dispatch = useDispatch();
    const modalProps: ModalPropsType[M] = useSelector(selectModalProps);

    // T is modalId which need to be open
    const openModal = useCallback(
        <T extends ModalName>(modalId: T, props?: ModalPropsType[T]) => {
            dispatch(openModalAction(modalId, props));
        },
        [dispatch]
    );

    const closeModal = useCallback(() => {
        dispatch(closeModalAction());
    }, [dispatch]);

    return { openModal, closeModal, modalProps };
};

export default useModals;
