import { selectUserFeatures } from 'store/user/selectors';

import { useUserLoggedIn } from 'hooks/user';
import { useSelector } from 'hooks/store';

export const usePersonalPlanAvailable = () => {
    const features = useSelector(selectUserFeatures);

    const isLoggedIn = useUserLoggedIn();

    return isLoggedIn && features?.length;
};
