import { ComponentType, lazy } from 'react';

const lazyWithRetry = (componentImport: () => Promise<{ default: ComponentType<any> }>) =>
    lazy(async () => {
        const isAlreadyReloaded = JSON.parse(localStorage.getItem('page-is-already-reloaded') || 'false');

        try {
            const component = await componentImport();

            localStorage.setItem('page-is-already-reloaded', 'false');

            return component;
        } catch (error) {
            if (!isAlreadyReloaded) {
                localStorage.setItem('page-is-already-reloaded', 'true');

                location.reload();
            }

            throw error;
        }
    });

export default lazyWithRetry;
