import { Colors } from 'wikr-core-components';
import { useTheme } from 'styled-components';

const ArrowAngleLeft = ({ color, size }: { color?: Colors; size?: number }) => {
    const theme = useTheme();
    const iconColor = theme[color || 'text-main'];

    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.71721 10.5463L11.777 16.7763C11.9172 16.9206 12.1043 17 12.3038 17C12.5033 17 12.6904 16.9206 12.8305 16.7763L13.2768 16.3176C13.5672 16.0188 13.5672 15.533 13.2768 15.2345L8.18822 10.0029L13.2825 4.76547C13.4226 4.62126 13.5 4.42901 13.5 4.22402C13.5 4.0188 13.4226 3.82656 13.2825 3.68224L12.8362 3.22366C12.6959 3.07945 12.5089 3 12.3094 3C12.11 3 11.9229 3.07945 11.7827 3.22366L5.71721 9.45941C5.57672 9.60407 5.49956 9.79723 5.5 10.0026C5.49956 10.2087 5.57672 10.4017 5.71721 10.5463Z"
                fill={iconColor}
            />
        </svg>
    );
};

export default ArrowAngleLeft;
