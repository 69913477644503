import { CancellationFlow, IReason } from './types';

export const OTHER_REASON = 'other';

export const PRESELECTED_REASON = {
    title: 'subscription.cancellationReason.foundLowPrice.title',
    flow: CancellationFlow.withCancellingOffer,
    shortKey: 'found_other_app_lower_price',
};

export const reasons: IReason[] = [
    PRESELECTED_REASON,
    {
        title: 'subscription.cancellationReason.noMotivation.title',
        flow: CancellationFlow.withCancellingOffer,
        shortKey: 'no_motivation',
    },
    {
        title: 'subscription.cancellationReason.noTime.title',
        flow: CancellationFlow.withCancellingOffer,
        shortKey: 'no_time_for_yoga',
    },
    {
        title: 'subscription.cancellationReason.hardToFollow.title',
        flow: CancellationFlow.withCancellingOffer,
        shortKey: 'hard_to_follow',
    },
    {
        title: 'subscription.cancellationReason.healthProblem.title',
        flow: CancellationFlow.withSupport,
        shortKey: 'health_problem',
    },
    {
        title: 'subscription.cancellationReason.dontLikeContent.title',
        flow: CancellationFlow.withSupport,
        shortKey: 'dont_like_content',
    },
    {
        title: 'subscription.cancellationReason.wantToTestApp.title',
        flow: CancellationFlow.withSupport,
        shortKey: 'want_to_try_app',
    },
    {
        title: 'subscription.cancellationReason.other.title',
        flow: CancellationFlow.withCancellingOffer,
        shortKey: 'other',
    },
];
