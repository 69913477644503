import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import HeaderPublic from 'components/Header/HeaderPublic';

import { LayoutProps } from '../types';

const AuthLayout = ({ children, titleKey }: LayoutProps) => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t(titleKey);
    }, [titleKey]);

    return (
        <>
            <HeaderPublic />
            {children}
        </>
    );
};

export default AuthLayout;
