import { ElementEventAction } from 'wikr-core-analytics';

import { trackClick } from './mainTrackers';

export const sendAnalyticKeepCurrentLevelButtonClick = () => {
    trackClick({
        elementEvent: ElementEventAction.button,
        // @ts-ignore
        actionEvent: 'repeat',
    });
};

export const sendAnalyticIncreaseLevelButtonClick = () => {
    trackClick({
        elementEvent: ElementEventAction.button,
        // @ts-ignore
        actionEvent: 'increase',
    });
};
