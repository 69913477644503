import { CURRENCY_SIGN_MAP } from 'constants/currency';

export enum Currencies {
    USD = 'USD',
    EUR = 'EUR',
    GBP = 'GBP',
    BRL = 'BRL',
    MXN = 'MXN',
    ARS = 'ARS',
    CLP = 'CLP',
    COP = 'COP',
    PEN = 'PEN',
}

export type CurrencySignType = typeof CURRENCY_SIGN_MAP[keyof typeof CURRENCY_SIGN_MAP];
