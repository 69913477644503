import { ActionEventAction, ElementEventAction } from 'wikr-core-analytics';

import { trackClick } from './mainTrackers';

export const sendAnalyticSideBarElementClick = (actionEvent: ActionEventAction) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent,
    });
};
