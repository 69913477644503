import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { selectCurrentUser } from 'store/user/selectors';
import { selectSubscriptions } from 'store/subscriptions/selectors';
import {
    cancelSubscription,
    fetchDiscountSubscription,
    setCancellationCandidates,
    updateSubscription,
} from 'store/subscriptions/actions';

import { DISCOUNT_TYPES } from 'constants/subscriptions';

import {
    sendAnalyticCancelSubscriptionClick,
    sendAnalyticSubscriptionCancelError,
    sendAnalyticSubscriptionCancelSuccess,
} from 'services/analytics';

import { useDispatch, useModals, useSelector } from 'hooks';

import { isMainSubscription } from 'helpers/subscriptions';
import { checkDiscountAllowed } from './helpers';

import { ModalName } from 'components/Modals/types';

import { ISubscription } from 'types/subscription';

const useCancelSubscriptionFlow = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { cancellationCandidates, discountSubscription, subscriptions } = useSelector(selectSubscriptions);

    const { country } = useSelector(selectCurrentUser);

    const { openModal, closeModal } = useModals();

    const initSubscriptionCancellation = useCallback(
        (candidate: ISubscription[]) => {
            const { product_code, external_id, discount_type } = candidate[0];
            const isCancellationCandidateMain = isMainSubscription(product_code);
            const isDiscountAllowed = checkDiscountAllowed(country);

            if (isCancellationCandidateMain && !discount_type && isDiscountAllowed) {
                dispatch(
                    fetchDiscountSubscription({
                        discount_type: DISCOUNT_TYPES.base,
                        external_id: external_id,
                    })
                );
            }

            openModal(ModalName.ConfirmationModal);

            dispatch(setCancellationCandidates(candidate));
            sendAnalyticCancelSubscriptionClick(candidate.map((e) => e.external_id));
        },
        [dispatch, openModal, country]
    );

    const discardCancellation = useCallback(() => {
        dispatch(setCancellationCandidates(null));
    }, [dispatch]);

    const onCancelSubscription = useCallback(
        (wasSecondCancelled?: boolean) => {
            if (!cancellationCandidates) return;

            openModal(ModalName.WaitingModal);

            dispatch(
                cancelSubscription(
                    cancellationCandidates.map((candidate) => {
                        const secondSubscription = subscriptions.find((e) => e.product_code !== candidate.product_code);

                        return {
                            currentSubscription: candidate,
                            ...(!wasSecondCancelled && { secondSubscription }),
                            onError: () => {
                                sendAnalyticSubscriptionCancelError(candidate.external_id);
                            },
                            onSuccess: () => {
                                sendAnalyticSubscriptionCancelSuccess(candidate.external_id);
                            },
                            onCancel: () => {
                                discardCancellation();
                                closeModal();
                            },
                        };
                    })
                )
            );
        },
        [cancellationCandidates, discardCancellation, closeModal, openModal, dispatch]
    );

    const onChangeSubscription = useCallback(() => {
        if (!discountSubscription || !cancellationCandidates) return;

        openModal(ModalName.WithLoaderModal, { title: t('subscription.discountOfferModal.updatingPlan') });

        dispatch(
            updateSubscription({
                product: discountSubscription,
                external_id: cancellationCandidates[0].external_id,
                discount_type: DISCOUNT_TYPES.base,
            })
        );
    }, [discountSubscription, dispatch, openModal, t, cancellationCandidates]);

    return {
        initSubscriptionCancellation,
        cancellationCandidates,
        onCancelSubscription,
        discardCancellation,
        onChangeSubscription,
        discountSubscription,
    };
};

export default useCancelSubscriptionFlow;
