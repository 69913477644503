import { Button, Colors } from 'wikr-core-components';

import * as S from './styled';

type OutlinedButtonPropsType = {
    text: string;
    onClick: () => void;
    dataLocator?: string;
    mb?: number;
    isLoading?: boolean;
    backgroundColor?: Colors;
};

const OutlinedButton = ({ text, onClick, dataLocator, mb, isLoading, backgroundColor }: OutlinedButtonPropsType) => {
    return (
        <S.Container mb={mb} borderColor="border-default" backgroundColor={backgroundColor}>
            <Button
                isLoading={isLoading}
                disabled={isLoading}
                text={text}
                textColor="text-main"
                onClick={onClick}
                dataLocator={dataLocator}
                backgroundColor={backgroundColor}
            />
        </S.Container>
    );
};

export default OutlinedButton;
