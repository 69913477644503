import { ERROR_LEVELS, SENTRY_AXIOS } from 'sentry-utils';

import config from 'config';

import { setToken, getToken } from 'api/utils/tokenManagement';

import { store } from 'store/configureStore';
import { logout } from 'store/auth/actions';

import sentry from 'services/Sentry/SentryInstance';

import { ClientError, initApiClient } from 'welltech-web-api-client';

const MAX_RETRIES = 3;

const ApiClient = () => {
    let retries = 0;

    return initApiClient({
        apiUrl: config.API_URL,
        apiVersion: config.API_VERSION,
        apiKey: config.API_KEY,
        retries: MAX_RETRIES,
        timeout: 35000,
        onRetry: () => {
            retries++;
        },
        getAuthToken: () => getToken(),
        onUpdateToken: (token) => {
            setToken(token);
        },
        onAuthorisationError: (error) => {
            const typedError = error as ClientError;

            const errorStatus = typedError?.response?.status;

            if (errorStatus === 403) {
                store.dispatch(logout());
            }
        },
        onErrorHandler: ({ extras, status, message }) => {
            const level = Number(status) >= 500 ? ERROR_LEVELS.CRITICAL : ERROR_LEVELS.ERROR;

            if (retries === MAX_RETRIES) {
                sentry.logError(new Error(message), SENTRY_AXIOS, level, extras);
            }
        },
    });
};

export default ApiClient();
