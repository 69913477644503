import { useEffect, useState } from 'react';

import { isProd } from 'helpers/utils';

import { getString } from 'firebase/remote-config';

import { activationConfigPromise, remoteConfig } from './initFirebase';
import { Experiment } from './experiments';
import { STAGE_EXPERIMENT_KEY } from './const';

const useVariant = (key: Experiment | string) => {
    const [variant, setVariant] = useState('');

    useEffect(() => {
        activationConfigPromise.then(() => {
            const envBasedKey = isProd() ? key : `${key}_${STAGE_EXPERIMENT_KEY}`;

            const remoteVariant = getString(remoteConfig, envBasedKey);

            setVariant(remoteVariant);
        });
    }, [key]);

    return variant;
};

export default useVariant;
