import styled from 'styled-components';

export const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
`;
