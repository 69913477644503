import styled, { css } from 'styled-components';

import { color } from 'theme/selectors';

// TODO: after icon button will be added to the lib component https://app.asana.com/0/1201994806276539/1205828583550215/f we don't need RoundButton any more
// This component is temporary. We use hex instead of color variable because of outdated lib components
const actionDisabled = '#bfbcbc';

export const Button = styled.button<{ isHidden?: boolean; size: number }>`
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    border-radius: ${({ size }) => size / 2}px !important;
    padding: 12px;
    background-color: ${color('surface-main')};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    ${({ isHidden }) =>
        isHidden &&
        css`
            visibility: hidden;
        `}
    svg {
        width: 20px;
        height: 20px;
        path {
            fill: ${color('action-main')};
        }
    }

    &:hover {
        background-color: ${color('surface-secondary')};
        svg path {
            fill: ${color('text-main')};
        }
    }
    &:disabled {
        cursor: not-allowed;
        svg path {
            fill: ${actionDisabled};
        }
        &:hover {
            background-color: ${color('surface-main')};
            svg path {
                fill: ${actionDisabled};
            }
        }
    }
`;
