import { Image, Text } from 'wikr-core-components';
import { Trans } from 'react-i18next';
import { useEffect } from 'react';

import { selectCompletedPlanStatistics } from 'store/personalPlan/selectors';
import { getCompletedPlanStatisticsRequest } from 'store/personalPlan/actions';

import { useDispatch, useSelector } from 'hooks';

import * as S from './styled';

import { getContent } from './config';

const ProgressBoard = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCompletedPlanStatisticsRequest());
    }, []);

    const statisticsData = useSelector(selectCompletedPlanStatistics);
    const { progressData } = getContent(statisticsData);

    return (
        <S.ProgressBoard>
            {progressData.map((progress) => (
                <S.ProgressBoardItem key={progress.title}>
                    <div data-locator={progress.dataLocator}>
                        <Image center maxWidth={18} mb={4} alt="Complete plan" src={progress.icon} />
                        <Text center color="text-secondary" type="caption">
                            <Trans
                                i18nKey={progress.title}
                                values={{ count: progress.count }}
                                components={{ 1: <br />, 2: <S.ProgressBoardItemNumber /> }}
                            />
                        </Text>
                    </div>
                </S.ProgressBoardItem>
            ))}
        </S.ProgressBoard>
    );
};

export default ProgressBoard;
