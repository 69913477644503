export const DOWNLOAD_APP_EVENT_LABELS = {
    ios_top: 'ios_top',
    android_top: 'android_top',
    general_bot: 'general_bot',
    ios_bot: 'ios_bot',
    android_bot: 'android_bot',
};

export const SUBSCRIPTION_SCREEN_ID = 'account_subscription';
export const DISCARD_CANCEL_OFFER_SCREEN_ID = 'cancel_offer_cancel';
export const KEEP_SUBSCRIPTION_EVENT = 'keep_subscription';
export const DISCOUNT_APPLIED_SUCCESS = 'discount_applied_success';
export const DISCOUNT_APPLIED_ERROR = 'discount_applied_error';
export const DISCOUNT_OFFER_SCREEN_ID = 'cancel_offer_discount';
export const RESTORE_POPUP_ID = 'restore_popup';
export const SUBSCRIPTION_RENEWAL_FAILED = 'subscription_renewal_failed';
export const SUBSCRIPTION_RENEWAL_SUCCESS = 'subscription_renewal_success';
