import { SessionEvent } from 'hooks/session/types';

import { trackEventFixed } from './mainTrackers';

type OverloadType = {
    (type: SessionEvent.StartMainSession, eventLabel: { session_id: string }): void;
    (type: SessionEvent.EndMainSession, eventLabel: { session_id: string; duration: number }): void;
    (type: SessionEvent.StartPlanSession, eventLabel: { session_id: string; plan_session_id: string }): void;
    (
        type: SessionEvent.EndPlanSession,
        eventLabel: { session_id: string; plan_session_id: string; duration: number }
    ): void;
};

export const sendAnalyticSessionEvent: OverloadType = (type: SessionEvent, eventLabel) => {
    // @ts-ignore
    trackEventFixed(type, eventLabel);
};
