import { combineReducers } from 'redux';

import UserReducer from './user/reducer';
import UiReducer from './uiEffects/reducer';
import SubscriptionsReducer from './subscriptions/reducer';
import sessionReducer from './session/reducer';
import personalPlanReducer from './personalPlan/reducer';
import ModalsReducer from './modals/reducer';
import LoadingReducer from './loading/reducer';
import GuidesReducer from './guides/reducer';
import ErrorsReducer from './errors/reducer';
import DeepLinkReducer from './deepLink/reducer';
import AuthReducer from './auth/reducer';

const rootReducer = combineReducers({
    auth: AuthReducer,
    user: UserReducer,
    subscriptions: SubscriptionsReducer,
    guides: GuidesReducer,
    ui: UiReducer,
    loading: LoadingReducer,
    errors: ErrorsReducer,
    deepLink: DeepLinkReducer,
    personalPlan: personalPlanReducer,
    modals: ModalsReducer,
    session: sessionReducer,
});

export default rootReducer;
