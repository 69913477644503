import { Box, Button, Image, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal/Modal';

import * as S from './styled';

import model from './img/model.webp';

import { GoalModalPropsType } from './types';

const GoalModal = ({ onClick }: GoalModalPropsType) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen onClose={onClick} paddingX={0} paddingY={0}>
            <Box paddingTop={16} paddingX={16} paddingBottom={44} backgroundColor="surface-default">
                <div data-locator="goalTodayWindow">
                    <Image maxWidth={390} mb={10} alt="model image" src={model} />
                    <Text type="h5" mb={4} text={t('personalPlan.planPreview.goalTitle.today')} />
                    <Text text={t('personalPlan.planPreview.goalModal.description')} />
                    <S.ButtonWrapper>
                        <Button
                            dataLocator="closeGoalTodayBtn"
                            text={t('personalPlan.planPreview.goalModal.button')}
                            onClick={onClick}
                        />
                    </S.ButtonWrapper>
                </div>
            </Box>
        </Modal>
    );
};

export default GoalModal;
