import Skeleton from 'components/Skeleton';

import { SkeletonWrapper } from './styled';

const SubscriptionsSkeleton = () => (
    <SkeletonWrapper>
        <Skeleton height={36} mb={12} />
        <Skeleton height={200} mb={24} />
    </SkeletonWrapper>
);

export default SubscriptionsSkeleton;
