import { ERROR_LEVELS, SENTRY_CABINET } from 'sentry-utils';

import sentry from 'services/Sentry/SentryInstance';
import { IError } from 'services/Sentry/interfaces';

import { isProd } from 'helpers/utils';
import { clearLocalConfig } from './helpers';

import { ensureInitialized, fetchAndActivate, getRemoteConfig, RemoteConfig } from 'firebase/remote-config';

import { initializeApp } from 'firebase/app';
import { firebaseConfig, MAX_RETRIES, remoteConfigSettings } from './const';

let remoteConfig: RemoteConfig;
let resolveActivation: () => void;

const activationConfigPromise = new Promise<void>((resolve) => {
    resolveActivation = resolve;
});

const initFirebase = async (): Promise<void> => {
    try {
        const app = initializeApp(firebaseConfig);

        remoteConfig = getRemoteConfig(app);
        remoteConfig.settings = remoteConfigSettings;

        await fetchAndActivateWithRetries(remoteConfig);

        if (!isProd()) clearLocalConfig();
    } catch (error) {
        sentry.logError(error as IError, SENTRY_CABINET, ERROR_LEVELS.CRITICAL);
    }
};

const fetchAndActivateWithRetries = async (remoteConfig: RemoteConfig, retries = MAX_RETRIES): Promise<void> => {
    try {
        await ensureInitialized(remoteConfig);
        await fetchAndActivate(remoteConfig);

        resolveActivation();
    } catch (error) {
        if (retries > 0) {
            console.error(`Fetch and activate failed. Retrying... (${MAX_RETRIES - retries + 1}/${MAX_RETRIES})`);
            await fetchAndActivateWithRetries(remoteConfig, retries - 1);
        } else {
            sentry.logError(error as IError, SENTRY_CABINET, ERROR_LEVELS.CRITICAL);
        }
    }
};

export { remoteConfig, activationConfigPromise, initFirebase };
