import { selectModals } from 'store/modals/selectors';

import { MODALS_LIST } from 'components/Modals/constants';

import { useSelector } from 'hooks';

const Modals = () => {
    const { modalId, modalProps } = useSelector(selectModals);

    const ModalComponent = modalId ? MODALS_LIST[modalId] : null;

    if (!ModalComponent) return null;

    return <ModalComponent {...modalProps} />;
};

export default Modals;
