import * as actionTypes from './actionTypes';

import { UserStore } from 'types/store/userStore';
import { IAuthBySignature, IBaseAuth, ISignInRequest } from './types';

export const authentication = ({ onError, onSuccess }: IBaseAuth) => {
    return {
        type: actionTypes.AUTHENTICATE,
        payload: {
            onError,
            onSuccess,
        },
    } as const;
};

export const authenticationBySignature = ({ uId, signature, onError, onSuccess }: IAuthBySignature) => {
    return {
        type: actionTypes.AUTHENTICATE_BY_SIGNATURE,
        payload: {
            onSuccess,
            onError,
            uId,
            signature,
        },
    } as const;
};

export const setAuthenticationStatus = (isAuthenticated: boolean) => {
    return {
        type: actionTypes.SET_AUTHENTICATION_STATUS,
        payload: isAuthenticated,
    } as const;
};

export const setAuthRedirectUrl = (pathname: string | null) => {
    return {
        type: actionTypes.SET_AUTHENTICATION_REDIRECT_URL,
        payload: pathname,
    } as const;
};

export const signInRequest = (payload: ISignInRequest) => {
    return {
        type: actionTypes.SIGN_IN_REQUEST,
        payload,
    } as const;
};

export const signInSuccess = (payload: UserStore) => {
    return {
        type: actionTypes.SIGN_IN_SUCCESS,
        payload,
    } as const;
};

export const signInError = (payload: Error) => {
    return {
        type: actionTypes.SIGN_IN_ERROR,
        payload,
    } as const;
};

export const logout = () => {
    return {
        type: actionTypes.LOG_OUT,
    } as const;
};

export const setAuthToken = (payload: string | null) => {
    return {
        type: actionTypes.SET_AUTH_TOKEN,
        payload,
    } as const;
};
