import {
    CancellationReasonModal,
    ConfirmationModal,
    WithLoaderModal,
    WaitingModal,
    DiscountModal,
    SuccessModal,
    ChangeSubscriptionErrorModal,
    CancelSubscriptionErrorModal,
    NoConnectionModal,
    QuitWorkoutModal,
    CompletePersonalPlanModal,
    GoalModal,
    ProblemZonesModal,
    FitnessLevelModal,
    RestoreSubscriptionModal,
    RestoreSubscriptionErrorModal,
    NoteModal,
    ConfirmationModalOld,
    CancellationReasonModalOld,
    CancelSubscriptionErrorModalOld,
    ChangeSubscriptionErrorModalOld,
    SuccessModalOld,
} from 'components/Modal';

import { ModalName, ModalsMapType } from './types';

export const MODALS_LIST: ModalsMapType = {
    [ModalName.DiscountModal]: DiscountModal,
    [ModalName.CancellationReasonModal]: CancellationReasonModal,
    [ModalName.CancellationReasonModalOld]: CancellationReasonModalOld,
    [ModalName.ConfirmationModal]: ConfirmationModal,
    [ModalName.ConfirmationModalOld]: ConfirmationModalOld,
    [ModalName.WaitingModal]: WaitingModal,
    [ModalName.WithLoaderModal]: WithLoaderModal,
    [ModalName.SuccessModal]: SuccessModal,
    [ModalName.SuccessModalOld]: SuccessModalOld,
    [ModalName.ChangeSubscriptionErrorModal]: ChangeSubscriptionErrorModal,
    [ModalName.ChangeSubscriptionErrorModalOld]: ChangeSubscriptionErrorModalOld,
    [ModalName.CancelSubscriptionErrorModal]: CancelSubscriptionErrorModal,
    [ModalName.CancelSubscriptionErrorModalOld]: CancelSubscriptionErrorModalOld,
    [ModalName.NoConnectionModal]: NoConnectionModal,
    [ModalName.QuitWorkoutModal]: QuitWorkoutModal,
    [ModalName.CompletePersonalPlanModal]: CompletePersonalPlanModal,
    [ModalName.GoalModal]: GoalModal,
    [ModalName.ProblemZonesModal]: ProblemZonesModal,
    [ModalName.FitnessLevelModal]: FitnessLevelModal,
    [ModalName.RestoreSubscriptionModal]: RestoreSubscriptionModal,
    [ModalName.RestoreSubscriptionErrorModal]: RestoreSubscriptionErrorModal,
    [ModalName.NoteModal]: NoteModal,
};
