import { useSelector } from 'react-redux';

import { selectCurrentUser } from 'store/user/selectors';

import { isProd } from 'helpers/utils';

import { getAll } from 'firebase/remote-config';

import { deleteInstallations, getInstallations } from 'firebase/installations';
import useVariant from './useVariant';
import { activationConfigPromise, initFirebase, remoteConfig } from './initFirebase';
import { ACTIVE_VARIANT, Experiment } from './experiments';
import { FIREBASE_DBs, FIREBASE_TTL_KEY, MAX_FETCH_INTERVAL_DEV_ENV, STAGE_EXPERIMENT_KEY } from './const';

export const checkFirebaseTokenExpired = () => {
    const storedTimeString = localStorage.getItem(FIREBASE_TTL_KEY);

    if (storedTimeString) {
        const storedTime = new Date(storedTimeString).getTime();

        const currentTime = new Date().getTime();

        const timeDifference = currentTime - storedTime;

        return timeDifference > MAX_FETCH_INTERVAL_DEV_ENV;
    } else {
        localStorage.setItem(FIREBASE_TTL_KEY, Date());
    }

    return false;
};

export const getExperimentsByEnv = () =>
    activationConfigPromise.then(() => {
        const allExperiments = getAll(remoteConfig);

        return Object.entries(allExperiments).reduce<Record<string, string>>(
            (envBasedExperiments, [experiment, variant]) => {
                const isDevEnvExperiment = experiment.includes(STAGE_EXPERIMENT_KEY);

                if ((isProd() && !isDevEnvExperiment) || (!isProd() && isDevEnvExperiment)) {
                    envBasedExperiments[experiment] = variant.asString();
                }

                return envBasedExperiments;
            },
            {}
        );
    });

export const clearLocalConfig = async () => {
    const isExpired = checkFirebaseTokenExpired();

    if (isExpired) {
        localStorage.removeItem(FIREBASE_TTL_KEY);

        const installations = getInstallations();

        await deleteInstallations(installations);

        await clearLocalDBs();

        initFirebase();
    }
};

export const clearLocalDBs = async () => {
    const databases = await indexedDB.databases();

    databases.forEach((db) => {
        if (db.name && FIREBASE_DBs.includes(db.name)) {
            indexedDB.deleteDatabase(db.name);
        }
    });
};

export const useIsActiveNewGroup = (experiment: Experiment, date: string) => {
    const { first_paid_order_created_at } = useSelector(selectCurrentUser);
    const variant = useVariant(experiment);

    if (!first_paid_order_created_at) return false;

    const firstPurchaseDate = new Date(first_paid_order_created_at);
    const dateFrom = new Date(date);

    return firstPurchaseDate >= dateFrom && variant === ACTIVE_VARIANT;
};
