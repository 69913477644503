import { Button, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';

import { selectCurrentUser } from 'store/user/selectors';
import { UPDATE_USER } from 'store/user/actionTypes';
import { updateUserRequest } from 'store/user/actions';
import { createProcessingSelector } from 'store/loading/selectors';

import { FitnessLevel } from 'constants/user';

import { useDispatch, useModals, useSelector } from 'hooks';

import { FITNESS_LEVEL_CARDS } from './components/FitnessLevelCard/helpers';

import OverlineButton from 'components/OverlineButton';
import Modal from 'components/Modal/Modal';
import FitnessLevelCard from './components/FitnessLevelCard';

import * as S from './styled';

const FitnessLevelModal = () => {
    const dispatch = useDispatch();

    const { fitness_level } = useSelector(selectCurrentUser);
    const { t } = useTranslation();
    const { closeModal } = useModals();

    const [selectedFitnessLevel, setSelectedFitnessLevel] = useState<FitnessLevel>(
        fitness_level ?? FitnessLevel.Beginner
    );

    const isLoading: boolean = useSelector(createProcessingSelector([UPDATE_USER]));

    const isSaveButtonDisabled = selectedFitnessLevel === fitness_level;

    const onCardClick = useCallback((level: FitnessLevel) => {
        setSelectedFitnessLevel(level);
    }, []);

    const onSave = () => {
        dispatch(
            updateUserRequest({
                payload: {
                    fitness_level: selectedFitnessLevel,
                },
                onSuccess: closeModal,
            })
        );
    };

    return (
        <Modal
            onClose={closeModal}
            withRoundedCorners={false}
            dataLocator="yogaLevelModal"
            mobileFullScreen
            isOpen
            shouldCloseOnOverlayClick
        >
            <S.ContentWrapper data-locator="fitnessLevelModal">
                <Text type="h4" center text={t('account.planSettings.yogaLevel')} />
                <S.ModalContent>
                    {FITNESS_LEVEL_CARDS.map(({ title, description, id, ...rest }) => (
                        <FitnessLevelCard
                            isActive={id === selectedFitnessLevel}
                            title={t(title)}
                            description={t(description)}
                            key={id}
                            onClick={onCardClick}
                            id={id}
                            {...rest}
                        />
                    ))}
                </S.ModalContent>
                <div>
                    <Button
                        dataLocator="save"
                        disabled={isSaveButtonDisabled}
                        mb={20}
                        text={t('basics.save')}
                        onClick={onSave}
                        isLoading={isLoading}
                    />
                    <OverlineButton dataLocator="cancelBtn" onClick={closeModal} text={t('basics.cancel')} />
                </div>
            </S.ContentWrapper>
        </Modal>
    );
};

export default FitnessLevelModal;
