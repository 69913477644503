import { ProblemZone } from 'constants/user';

const filterSelectedZones = (selectedZones: ProblemZone[], zone: ProblemZone) => {
    if (selectedZones.includes(zone)) {
        return selectedZones.filter((selectedZone) => selectedZone !== zone);
    } else {
        return [...selectedZones, zone];
    }
};

const checkZonesEqual = (selectedZones: ProblemZone[], userApiZone: ProblemZone[]) =>
    selectedZones.length === userApiZone.length && selectedZones.every((zone) => userApiZone.includes(zone));

export { filterSelectedZones, checkZonesEqual };
