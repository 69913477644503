import routes from 'router/routes';

import { DEFAULT_LANGUAGE } from 'constants/localization';

import isMobile from 'helpers/isMobile';

export const getFaqLinks = (value: string) => {
    const mobilePlatform = (isMobile.iOS() && '8266653228061-iOS') || (isMobile.android() && '8250895372061-Android');

    const zendeskDefaultLang = 'en-us';
    const isDefaultLangNeededSet = !value || value === DEFAULT_LANGUAGE;
    const lang = isDefaultLangNeededSet ? zendeskDefaultLang : value;

    if (mobilePlatform) {
        return `https://support.yoga-go.io/hc/${lang}/categories/${mobilePlatform}`;
    }

    return `https://support.yoga-go.io/hc/${lang}`;
};

export const getPersonalPlanLinks = (language: string) => {
    if (isMobile.any()) {
        const mobilePlatform = isMobile.iOS() ? 8849172272669 : 8849174250269;

        return `https://support.yoga-go.io/hc/${language}/articles/${mobilePlatform}-Desktop-version`;
    }

    return routes.PRIVATE.PERSONAL_PLAN.path;
};
