import { Theme } from 'wikr-core-components';
import { createGlobalStyle } from 'styled-components';

import flexHelpers from './styles/helpers/flex';

import common from 'theme/styles/common';
import resetStyles from './styles/reset';
import { color } from './selectors';

const GlobalStyle = createGlobalStyle<{ rootVariables: string; theme: Theme }>`
    ${resetStyles}

    :root {
        ${({ rootVariables }) => rootVariables}
    }

    body,
    html{
        height: 100%;
        font-family: 'Roboto', sans-serif;
    }

    body {
         background: ${color('surface-main')};

        &.ReactModal__Body--open {
            overflow: hidden;
        }
    }
    
    button {
      //todo: rm this after <Button borderRadius={false} /> issue fix
      border-radius: 0 !important;
      cursor: pointer;
    }
    
    ${common}
    ${flexHelpers}
`;

export default GlobalStyle;
