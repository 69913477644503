/* eslint-disable max-lines */
import { Image, Text } from 'wikr-core-components';
import { Trans, useTranslation } from 'react-i18next';

import { selectCurrentUser } from 'store/user/selectors';
import { selectCurrentPlanLevel, selectTotalPlanDays } from 'store/personalPlan/selectors';
import { generatePlanRequest, setCompletePlanModalVisibility } from 'store/personalPlan/actions';

import {
    sendAnalyticIncreaseLevelButtonClick,
    sendAnalyticKeepCurrentLevelButtonClick,
} from 'services/analytics/trackers/completePersonalPlan';

import { useDispatch, useSelector } from 'hooks';

import { increaseComplexityLevel } from 'helpers/workout';
import { isTablet } from 'helpers/utils';
import isMobile from 'helpers/isMobile';

import ProgressBoard from 'containers/Workouts/components/ProgressBoard';

import Cross from 'components/Icon/Cross';

import * as S from './styled';

import lotus from './assets/img/lotus.png';
import completePlanTabletImg from './assets/img/complete_plan_tablet.png';
import completePlanMobileImg from './assets/img/complete_plan_mobile.png';
import completePlanDesktopImg from './assets/img/complete_plan_desktop.png';
import check from './assets/img/check.png';

const CompletePersonalPlanModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const currentLevel = useSelector(selectCurrentPlanLevel);
    const { name } = useSelector(selectCurrentUser);
    const { newLevel, isPossibleToIncreaseLevel } = increaseComplexityLevel(currentLevel);

    const totalPlanDays = useSelector(selectTotalPlanDays);

    const imgUrl = () => {
        if (isMobile.any()) return completePlanMobileImg;
        if (isTablet()) return completePlanTabletImg;

        return completePlanDesktopImg;
    };

    const onClose = () => dispatch(setCompletePlanModalVisibility(false));

    const handleIncreaseLevel = () => {
        onClose();

        sendAnalyticIncreaseLevelButtonClick();
        dispatch(generatePlanRequest({ level: newLevel }));
    };

    const handleKeepLevel = () => {
        onClose();

        sendAnalyticKeepCurrentLevelButtonClick();
        dispatch(generatePlanRequest({ level: currentLevel }));
    };

    return (
        <S.Container>
            <S.CloseBtn onClick={onClose}>
                <Cross />
            </S.CloseBtn>

            <S.TopWrapper>
                <S.TopWrapperContent>
                    <S.TopWrapperRightCol center maxWidth={450} mb={20} alt="Complete plan" src={imgUrl()} />
                    <S.TopWrapperLeftCol>
                        <S.TopWrapperSubtitle>
                            <S.TopWrapperSubtitleIcon maxWidth={20} alt="Complete icon" src={check} />
                            <div data-locator="durationCompletedPlan">
                                <S.TopWrapperSubtitleText
                                    medium
                                    color="text-secondary"
                                    text={t('personalPlan.completePlanModal.subtitle', { days: totalPlanDays })}
                                />
                            </div>
                        </S.TopWrapperSubtitle>
                        <S.TopWrapperLeftColText
                            type="h4"
                            medium
                            mb={24}
                            text={t('personalPlan.completePlanModal.title')}
                        />
                        <ProgressBoard />
                    </S.TopWrapperLeftCol>
                </S.TopWrapperContent>
            </S.TopWrapper>

            <S.BottomWrapper>
                <S.BottomWrapperHeader>
                    <span role="img" aria-label="fire">
                        🔥
                    </span>
                    <Text type="overline" text={t('personalPlan.completePlanModal.youAreBetterThan')} />
                </S.BottomWrapperHeader>
                <S.BottomWrapperBody>
                    <S.BottomWrapperBodyCol>
                        <S.BottomWrapperBodyTitle>
                            <Image maxWidth={24} alt="Complete plan" src={lotus} />
                            <div data-locator="wellDoneMessage">
                                <Text
                                    medium
                                    type="large-text"
                                    text={t('personalPlan.completePlanModal.wellDone.title', { name })}
                                />
                            </div>
                        </S.BottomWrapperBodyTitle>
                        <Text mb={20} color="text-secondary" type="small-text">
                            <Trans
                                i18nKey="personalPlan.completePlanModal.wellDone.description"
                                components={{ 1: <br /> }}
                            />
                        </Text>
                    </S.BottomWrapperBodyCol>
                    <S.BottomWrapperBodyCol>
                        {isPossibleToIncreaseLevel && (
                            <S.BottomWrapperIncreaseLeaveBtn
                                dataLocator="IncreaseLvlBtn"
                                onClick={handleIncreaseLevel}
                                backgroundColor="primary-default"
                                mb={12}
                                text={t('personalPlan.completePlanModal.wellDone.increaseLevelBtn')}
                            />
                        )}
                        <S.BottomWrapperKeepLevelBtn
                            onClick={handleKeepLevel}
                            dataLocator="keppCurrentLvlBtn"
                            backgroundColor="surface-main"
                            mb={20}
                            text={t('personalPlan.completePlanModal.wellDone.keepLevelBtn')}
                        />
                    </S.BottomWrapperBodyCol>
                </S.BottomWrapperBody>
            </S.BottomWrapper>
        </S.Container>
    );
};

export default CompletePersonalPlanModal;
