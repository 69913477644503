// Temporary solution while we are not decided whether use library or custom solution

const MILLISECONDS_PER_DAY = 86400000;
const EXPIRES_DATE_FOR_DELETE = '; expires=Thu, 01 Jan 1970 00:00:01 GMT';

export const setCookie = (name: string, value: string, days?: number) => {
    let expires = '';

    if (days) {
        const date = new Date();

        date.setTime(date.getTime() + days * MILLISECONDS_PER_DAY);

        expires = `; expires=${date.toUTCString()}`;
    }

    document.cookie = `${name}=${value || ''}${expires}; path=/; SameSite=Strict;`;
};

export const deleteCookie = (name: string, path?: string, domain?: string) => {
    if (getCookie(name)) {
        const pathParam = path ? `; path=${path}` : '';
        const domainParam = domain ? `; domain=${domain}` : '';

        document.cookie = `${name}=${pathParam}${domainParam}${EXPIRES_DATE_FOR_DELETE}`;
    }
};

export const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length === 2) return parts.pop()?.split(';').shift();
};
