import { Button, Text, Image } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { useCancelSubscriptionFlow, useModals, useSubscriptions } from 'hooks';

import OutlinedButton from 'components/OutlinedButton';
import Modal from 'components/Modal/Modal';

import hands from 'assets/emoji/raised_hands2.png';

const NoteModal = ({ cancelledProductCode }: { cancelledProductCode: string }) => {
    const { closeModal } = useModals();
    const { t } = useTranslation();

    const { subscriptions } = useSubscriptions();

    const { initSubscriptionCancellation } = useCancelSubscriptionFlow();

    const subscriptionToCancel = useMemo(
        () => subscriptions.find((subscription) => subscription.product_code !== cancelledProductCode),
        [subscriptions, cancelledProductCode]
    );

    return (
        <Modal onClose={closeModal} isOpen style={{ content: { padding: '16px' } }} withRoundedCorners={false}>
            <Image maxWidth={56} center alt="emoji" src={hands} mb={24} isForceVisible />
            <Text type="h5" mb={12} text={t('subscription.cancel.note.title')} center />
            <Text
                text={t('subscription.cancel.note.subtitle', { context: subscriptionToCancel?.product_code })}
                mb={24}
                center
            />
            <Button
                backgroundColor="primary-default"
                text={t('subscription.cancelDialog.discard')}
                onClick={closeModal}
                mb={16}
            />
            <OutlinedButton
                onClick={() => subscriptionToCancel && initSubscriptionCancellation([subscriptionToCancel])}
                text={t('subscription.discountOfferModal.headerTitle')}
                mb={4}
            />
        </Modal>
    );
};

export default NoteModal;
