import { NavLink, useLocation } from 'react-router-dom';
import { memo } from 'react';

import * as S from '../styled';

import { linkType, SidebarLinkProps } from '../types';

import SidebarLinkButton from './SidebarLinkButton';

const SidebarLink = ({
    to,
    title,
    onClick,
    dataLocator,
    type,
    target = '_self',
    rel,
    itemLabel: ItemLabel,
}: SidebarLinkProps) => {
    const handleClick = () => onClick && onClick(to);
    const { pathname } = useLocation();

    const isActive = pathname === to || pathname.startsWith(`${to}/`);
    const isPrimary = linkType.Primary === type;

    const linkProps = {
        'data-locator': dataLocator,
        onClick: handleClick,
        target,
        rel,
    };

    const commonButtonProps = {
        isActive,
        isPrimary,
        title,
    };

    return (
        <NavLink to={to} {...linkProps}>
            <S.SidebarLinkWrapper>
                <SidebarLinkButton {...commonButtonProps} />
                {ItemLabel && <ItemLabel />}
            </S.SidebarLinkWrapper>
        </NavLink>
    );
};

export default memo(SidebarLink);
