import { ActionEventAction, ElementEventAction, EventTriggerFixed } from 'wikr-core-analytics';

import { trackClick, trackEventFixed } from './mainTrackers';

export const sendAnalyticProfileUpdateSuccess = (updatedFields: string[]) => {
    trackEventFixed(EventTriggerFixed.profileUpdateSuccess, updatedFields);
};

export const sendAnalyticProfileUpdateError = () => {
    trackEventFixed(EventTriggerFixed.profileUpdateError);
};

export const sendAnalyticTakeQuizClick = () => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.takeQuiz,
    });
};
