import { ActionEventAction, ElementEventAction, EventTrigger, EventTriggerFixed, Main } from 'wikr-core-analytics';

import { changePathNameToScreenId } from '../helpers';

interface TrackClickData {
    elementEvent?: ElementEventAction;
    actionEvent?: ActionEventAction;
    screenIdParam?: string;
    eventLabel?: string | object;
}

export const trackScreenLoad = (screenIdParam?: string, eventLabel?: string | string[] | { [key: string]: any }) => {
    const screenId = screenIdParam || changePathNameToScreenId();

    Main.trackNew({
        eventData: { event: EventTrigger.screenLoad, screenId },
        actionData: {
            elementEvent: ElementEventAction.screen,
            actionEvent: ActionEventAction.load,
        },
        ...(eventLabel && { data: { event_label: eventLabel } }),
    });
};

export const trackEventFixed = (event: EventTriggerFixed, eventLabel?: string | string[]) => {
    Main.trackNew({
        eventData: event,
        ...(eventLabel && { data: { event_label: eventLabel } }),
    });
};

export const trackClick = ({ elementEvent, actionEvent, screenIdParam, eventLabel }: TrackClickData) => {
    const screenId = screenIdParam ? screenIdParam : changePathNameToScreenId();

    Main.trackNew({
        eventData: { event: EventTrigger.click, screenId },
        ...(elementEvent && actionEvent && { actionData: { elementEvent, actionEvent } }),
        ...(eventLabel && { data: { event_label: eventLabel } }),
    });
};
