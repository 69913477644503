import * as actionTypes from './actionTypes';

import { FitnessLevel } from 'constants/user';

import { PersonalPlanState, ActionType } from './types';

const initialState: PersonalPlanState = {
    currentDay: 1,
    planDays: [],
    isCurrentPlanComplete: false,
    isCompletePlanModalVisible: false,
    currentPlanId: null,
    currentPlanLevel: FitnessLevel.Beginner,
    totalPlanDays: 0,
    statistics: {
        days: 0,
        duration: null,
        kcal: null,
    },
    workout: {
        blocks: [],
        duration: null,
        exercise_count: null,
        calories: null,
    },
    yogaType: null,
};

const personalPlanReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.GET_COMPLETED_PLAN_STATISTICS_SUCCESS:
            return { ...state, statistics: { ...action.payload.data } };

        case actionTypes.GET_CURRENT_PLAN_SUCCESS:
            return {
                ...state,
                currentPlanLevel: action.payload.days[0].level,
                planDays: action.payload.days,
                totalPlanDays: action.payload.days.length,
                currentDay: action.payload.current_day,
                currentPlanId: action.payload.id,
                yogaType: action.payload.yoga_type,
            };

        case actionTypes.GET_WORKOUT_SUCCESS:
            return { ...state, workout: { ...action.payload.data } };

        case actionTypes.SET_CURRENT_PLAN_COMPLETION:
            return { ...state, isCurrentPlanComplete: action.payload };

        case actionTypes.SET_COMPLETE_PLAN_MODAL_VISIBILITY:
            return { ...state, isCompletePlanModalVisible: action.payload };

        case actionTypes.SET_LAST_PLAN_DAY_HATCH:
            return {
                ...state,
                currentDay: action.payload.currentDay,
                planDays: action.payload.workouts,
            };

        default:
            return state;
    }
};

export default personalPlanReducer;
