export const PRIVATE = {
    ACCOUNT: {
        path: '/account/:tabId',
        titleKey: 'pagesTitles.account',
        pathToFile: 'account',
    },
    ACCOUNT_DEFAULT: {
        path: '/account',
        titleKey: 'pagesTitles.account',
        pathToFile: 'account',
    },
    CHANGE_EMAIL: {
        path: '/change-email',
        titleKey: 'pagesTitles.changeEmail',
        pathToFile: 'change-email',
    },
    CHANGE_PASSWORD: {
        path: '/change-password',
        titleKey: 'pagesTitles.changePassword',
        pathToFile: 'change-password',
    },
    AUTHORISED_FORGOT_PASSWORD: {
        path: '/forgot-password-a',
        titleKey: 'pagesTitles.forgotPassword',
        pathToFile: 'forgot-password-a',
    },
    FAQ: {
        path: '/faq',
        titleKey: 'pagesTitles.FAQ',
        pathToFile: 'faq',
    },
    MAIN: {
        path: '/main',
        titleKey: 'pagesTitles.installApp',
        pathToFile: 'install-app',
    },
    FITNESS_GUIDES: {
        path: '/guides',
        titleKey: 'pagesTitles.guides',
        pathToFile: 'guides',
    },
    WORKOUT_COMPLETED: {
        path: '/workout-completed',
        titleKey: 'pageTitles.plan',
        pathToFile: 'workout-completed',
    },
    PERSONAL_PLAN: {
        path: '/plan',
        titleKey: 'pageTitles.plan',
        pathToFile: 'personal-plan',
    },
    WORKOUT: {
        path: '/plan/play/:workoutDay',
        titleKey: 'pagesTitles.workout',
        pathToFile: 'workout-page',
    },
};

export const PUBLIC = {
    LOGIN: {
        path: '/',
        titleKey: 'pagesTitles.login',
        pathToFile: 'login',
    },
    FORGOT_PASSWORD: {
        path: '/forgot-password',
        titleKey: 'pagesTitles.forgotPassword',
        pathToFile: 'forgot-password',
    },
};

export default {
    PRIVATE,
    PUBLIC,
};
