import { matchPath, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { PRIVATE } from 'router/routes';

import { selectMainSessionId } from 'store/session/selectors';

import { sendAnalyticSessionEvent } from 'services/analytics/trackers/session';

import { useSelector } from 'hooks/store';

import { uuidv4 } from 'helpers/utils';
import { getDurationInSeconds } from './helpers';

import { SessionEvent } from './types';

const PLAN_PAGES_WITH_SESSION = [PRIVATE.PERSONAL_PLAN.path, PRIVATE.WORKOUT.path, PRIVATE.WORKOUT_COMPLETED.path];

const usePlanSessionTracker = () => {
    const { pathname } = useLocation();

    const mainSessionId = useSelector(selectMainSessionId);

    const planSessionStartTime = useRef(0);
    const planSessionId = useRef('');

    const isPlanPage = useMemo(() => !!PLAN_PAGES_WITH_SESSION.find((page) => matchPath(page, pathname)), [pathname]);

    const startPlanSessionHandler = useCallback(() => {
        if (!isPlanPage || !mainSessionId) return;

        planSessionId.current = uuidv4();
        planSessionStartTime.current = Date.now();

        sendAnalyticSessionEvent(SessionEvent.StartPlanSession, {
            session_id: mainSessionId,
            plan_session_id: planSessionId.current,
        });
    }, [mainSessionId, planSessionId, isPlanPage]);

    const endPlanSessionHandler = useCallback(() => {
        if ((!isPlanPage && !planSessionStartTime.current) || !mainSessionId) return;

        sendAnalyticSessionEvent(SessionEvent.EndPlanSession, {
            session_id: mainSessionId,
            plan_session_id: planSessionId.current,
            duration: getDurationInSeconds(planSessionStartTime.current),
        });

        planSessionStartTime.current = 0;
        planSessionId.current = '';
    }, [mainSessionId, planSessionId, planSessionStartTime, isPlanPage]);

    const onPlanRouteChange = useCallback(() => {
        if (!isPlanPage && planSessionStartTime.current) {
            endPlanSessionHandler();
        } else if (isPlanPage && !planSessionStartTime.current) {
            startPlanSessionHandler();
        }
    }, [endPlanSessionHandler, startPlanSessionHandler, isPlanPage]);

    useEffect(() => {
        startPlanSessionHandler();
    }, [startPlanSessionHandler]);

    return {
        startPlanSessionHandler,
        endPlanSessionHandler,
        onPlanRouteChange,
    };
};

export default usePlanSessionTracker;
