import { Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import { ArrowAngleLeft, Cross } from 'components/Icon';
import RoundButton from 'components/Buttons/RoundButton';

import * as S from './styled';

import { DiscountModalHeaderPropsType } from './types';

const DiscountModalHeader = ({ onBackButtonClick, closeModal }: DiscountModalHeaderPropsType) => {
    const { t } = useTranslation();

    return (
        <S.DiscountModalHeader>
            <RoundButton onClick={onBackButtonClick}>
                <ArrowAngleLeft />
            </RoundButton>
            <Text medium color="text-main" type="large-text" text={t('subscription.discountOfferModal.headerTitle')} />
            <RoundButton onClick={closeModal}>
                <Cross />
            </RoundButton>
        </S.DiscountModalHeader>
    );
};

export default DiscountModalHeader;
