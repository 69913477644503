export const mediaQueries = {
    mobile: '@media (min-width: 321px)',
    tablet: '@media (min-width: 429px)',
    laptop: '@media (min-width: 768px)',
    tabletLandscape: '@media (min-width: 1024px)',
    desktop: '@media (min-width: 1440px)',
    largeDesktop: '@media (min-width: 1900px)',
};

export const gridBreakpoints = {
    // 0-599px is a mobile range.
    // There is no value for mobile because we follow mobile first, firstly we write styles for mobile
    tablet: 600,
    largeTablet: 905,
    desktop: 1240,
    largeDesktop: 1536,
};

export const mediaQueriesNew = {
    tablet: `@media (min-width: ${gridBreakpoints.tablet}px)`,
    largeTablet: `@media (min-width: ${gridBreakpoints.largeTablet}px)`,
    desktop: `@media (min-width: ${gridBreakpoints.desktop}px)`,
    largeDesktop: `@media (min-width: ${gridBreakpoints.largeDesktop}px)`,
};
