import { styled } from 'styled-components';

import { PillWrapper } from 'components/Pill/styled';

import { mediaQueriesNew } from 'theme/mediaQueries';

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    padding-bottom: 40px;

    ${mediaQueriesNew.tablet} {
        padding-bottom: 0;
    }
`;

export const ModalContent = styled(PillWrapper)`
    padding: 40px 0;
`;
