type IQACommand = {
    hotkey: string;
    description: string;
};
export const QA_COMMANDS: Record<string, IQACommand> = {
    LAST_PLAN_DAY: {
        hotkey: 'shift+l+d',
        description:
            'jumps to the last day of the plan. Available on the personal plan page only. Not available on prod',
    },
};

export const addQACommandsToWindow = () => {
    window.QA = {};
    const values = Object.values(QA_COMMANDS);

    for (const { hotkey, description } of values) {
        window.QA[hotkey] = description;
    }
};
