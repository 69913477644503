import ReactModal from 'react-modal';
import { ComponentPropsWithRef, ReactNode, memo } from 'react';

import { Cross } from 'components/Icon';

import { ContentStyle, OverlayStyle, CloseButton, ModalWithStyles } from './styled';

import { IModal } from './types';

ReactModal.defaultStyles = {};

const Modal = ({
    children,
    isOpen,
    onClose,
    shouldCloseOnOverlayClick,
    withCloseButton,
    fullscreen,
    paddingY,
    paddingX,
    customContent,
    style,
    withRoundedCorners = true,
    dataLocator,
    mobileFullScreen,
    customStyles,
}: IModal) => {
    const contentElement = (props: ComponentPropsWithRef<'div'>, content: ReactNode) => {
        if (customContent) return <>{content}</>;

        return (
            <ContentStyle
                {...props}
                data-locator={dataLocator}
                fullscreen={Boolean(fullscreen)}
                mobileFullScreen={Boolean(mobileFullScreen)}
                paddingX={paddingX}
                paddingY={paddingY}
                style={customStyles}
            >
                {content}
            </ContentStyle>
        );
    };

    const overlayElement = (props: ComponentPropsWithRef<'div'>, contentElement: ReactNode) => (
        <OverlayStyle {...props}>{contentElement}</OverlayStyle>
    );

    return (
        <ModalWithStyles
            mobileFullScreen={mobileFullScreen}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            withRoundedCorners={withRoundedCorners}
            shouldCloseOnEsc={false}
            isOpen={isOpen}
            contentLabel="Modal"
            contentElement={contentElement}
            overlayElement={overlayElement}
            ariaHideApp={false}
            style={style}
        >
            {withCloseButton && (
                <CloseButton onClick={onClose}>
                    <Cross color="action-main" />
                </CloseButton>
            )}
            {children}
        </ModalWithStyles>
    );
};

export default memo(Modal);
