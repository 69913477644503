import { all, call } from 'redux-saga/effects';

import watchUser from './user/sagas';
import watchSubscriptions__Old from './subscriptions/sagas__Old';
import watchSubscriptions from './subscriptions/sagas';
import watchPersonalPlan from './personalPlan/sagas';
import watchNotifications from './notifications/sagas';
import watchGuides from './guides/sagas';
import watchDeepLink from './deepLink/sagas';
import watchAuth from './auth/sagas';

export default function* rootSaga() {
    yield all([
        call(watchUser),
        call(watchDeepLink),
        call(watchAuth),
        call(watchNotifications),
        call(watchSubscriptions),
        call(watchSubscriptions__Old),
        call(watchGuides),
        call(watchPersonalPlan),
    ]);
}
