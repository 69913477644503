export const DATE_FORMAT = 'DD-MM-YYYY';

export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';

export const DATE_FORMATS = {
    DMY: 'DD/MM/YYYY',
    YMD: 'YYYY-MM-DD',
    MMM: 'MMM',
    MMMM: 'MMMM',
    DD: 'DD',
    YYYY: 'YYYY',
};
