import * as action from './actions';

import * as actionTypes from './actionTypes';

import { uuidv4 } from 'helpers/utils';

import { InferValueTypes } from 'types/commonTypes';
import { SessionStoreType } from './types';

export const initialState: SessionStoreType = {
    mainSessionId: uuidv4(),
};

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const sessionReducer = (state: SessionStoreType = initialState, action: ActionType): SessionStoreType => {
    switch (action.type) {
        case actionTypes.SET_MAIN_SESSION_ID:
            return { ...state, mainSessionId: action.payload };

        default:
            return state;
    }
};

export default sessionReducer;
