import { DATE_FORMATS } from 'constants/date';

import dayjs from 'dayjs';

export const getFormatedDate = (expirationDate: string) => {
    return {
        targetMonth: dayjs(expirationDate).format(DATE_FORMATS.MMM),
        targetDay: dayjs(expirationDate).format(DATE_FORMATS.DD),
        targetYear: dayjs(expirationDate).format(DATE_FORMATS.YYYY),
    };
};
