import { Text } from 'wikr-core-components';

import * as S from './styled';

import { PillProps, PillWrapperProps } from './types';

const Pill = <T,>({ title, isActive, id, onClick }: PillProps<T>) => (
    <S.Pill data-locator="pill" isActive={isActive} onClick={() => onClick(id)}>
        <Text text={title} type="small-text" bold />
    </S.Pill>
);

const PillWrapper = ({ children }: PillWrapperProps) => <S.PillWrapper>{children}</S.PillWrapper>;

export { Pill, PillWrapper };
